/* eslint-disable no-unused-vars */
import { Types } from 'mongoose';
import { Answers } from 'Client/pages/proposals/types';
import { getPivotValue } from 'Client/pages/proposals/utils/getPivotValue';
import { createCommentContribution } from 'Client/services/contributions/createCommentContribution.gql';
import { updateAcornContribution } from 'Client/services/contributions/updateContribution.gql';
import { uploadVoiceAnswersToS3 } from 'Client/services/contributions/uploadVoiceAnswersToS3';
import { getConsentsByEmailProject } from 'Client/services/consent/getConsents.gql';
import { findOrCreateUser } from 'Client/services/user/findOrCreateUser.gql';
import { getUserById } from 'Client/services/user/findUserById.gql';
import { reverseGeocode } from 'Client/services/geocode/reverseGeocode';
import { updateUser } from 'Client/services/user/updateUser.gql';
import { getUserByEmail } from 'Client/services/user/getUserByEmail.gql';
import { SupportedLanguages } from 'Client/constants/languages';
import { getDemographicsByUserByProject } from 'Client/services/demographics/getDemographicsByUserByProject.gql';
import { User, UserStatus } from 'Shared/types/user';
import { DemographicsAcorn } from 'Shared/types/demographics';
import { ProjectProps } from 'Shared/types';
import { Consent } from 'Shared/types/consent';
import getClient from 'Shared/utils/apollo-client';
import {
  Geometry,
  Contribution,
  ContributionStatus,
  ContributionType,
  PopulatedAnswer,
} from 'Shared/types/contribution';
import {
  CHECK_POSTGRES_CONTRIBUTION,
  CREATE_POSTGRES_CONTRIBUTION,
  UPDATE_POSTGRES_CONTRIBUTION,
} from 'Server/utils/gql/geolytix.gql';
import { PgContributionFields } from 'Server/services/contribution/types';
import { buildAnswersForMapFilters } from 'Server/services/contribution/buildContributionDataToMapFilters';
import { moderateCommentGql } from 'Server/services/comment-moderation/ModerateComment.gql';

interface ContributionConstructorProps {
  project: ProjectProps;
  pageId: string;
  surveySlug: string;
  language: SupportedLanguages;
  apiToken: string;
  existentContribution?: Partial<Contribution<'comment'>>;
  map: boolean;
}
export class CommentContribution implements Contribution<'comment'> {
  _id: string;
  projectId: string;
  pageId: string;
  type: ContributionType.COMMENT;
  status: ContributionStatus;
  draft: boolean;
  origin: string;
  date: string;
  surveySlug?: string;
  userId?: string;
  answersPopulated: Array<PopulatedAnswer>;
  answers: Answers;
  voiceAnswers?: Record<string, string>;
  language: SupportedLanguages;
  utils: {
    apiToken: string;
    dbEntryCreated: boolean;
    userCreatedOnThisCF: boolean;
  };
  dataHandlers: {
    demographics: DemographicsAcorn;
    project: ProjectProps;
    user: User;
    consents: Consent[];
  };
  demographicsId?: string;
  mapUtils: {
    sentimentQuestionId: string;
  };
  map: boolean;
  reversedGeocode?: string;
  location?: Geometry | null;
  sentiment?: number | null;

  constructor({
    project,
    pageId,
    surveySlug,
    language,
    apiToken, // existentContribution,
    map = false,
  }: ContributionConstructorProps) {
    this._id = String(new Types.ObjectId());
    this.type = ContributionType.COMMENT;
    this.status = ContributionStatus.ANONYMOUS;
    this.draft = true;
    this.date = new Date().toISOString();
    this.surveySlug = surveySlug;
    this.projectId = project._id;
    this.pageId = pageId;
    this.origin = 'CF 3.0';
    this.language = language;
    this.answersPopulated = [];
    this.answers = {};
    this.map = map;
    this.utils = {
      apiToken,
      dbEntryCreated: false,
      userCreatedOnThisCF: false,
    };
    this.dataHandlers = {
      demographics: null,
      project: project,
      user: null,
      consents: [],
    };
    this.mapUtils = {
      sentimentQuestionId: '0',
    };
  }
  /**
   *
   * @param user the logged in user (from useUser())
   * @param email the email from the drawer or email page
   * @returns success
   */
  async assignUser(user: User, email?: string) {
    // this.utils.userCreatedOnThisCF = false;
    if (user?._id) {
      if (!this.dataHandlers.user) {
        const userRes = await getUserById(user._id, this.utils.apiToken);

        this.dataHandlers.user = userRes;
      }
      if (this.userId === user._id) return { success: true };
      this.userId = user._id;
      this.dataHandlers.user = user;
      if (user?.status !== UserStatus.CONFIRMED) {
        this.status = ContributionStatus.PENDING;
      } else {
        this.status = ContributionStatus.CONFIRMED;
      }
    }
    if (!this.userId && email) {
      const userRes = await findOrCreateUser(this.utils.apiToken, email, {
        status: UserStatus.PENDING,
        createdAt: new Date(),
        language: this.language,
      });
      this.userId = userRes.user._id;
      this.dataHandlers.user = userRes.user;
      this.utils.userCreatedOnThisCF = userRes.newUser;
    }
    /* User changed email mid-flow  */
    if (this.dataHandlers.user.email !== email) {
      if (!email) return; //

      if (this.utils.userCreatedOnThisCF) {
        const existentUser = await getUserByEmail(this.utils.apiToken, email);
        /* If the new email if from an already existent user we update the userId */
        if (existentUser) {
          this.dataHandlers.user = existentUser;
          this.userId = existentUser._id;
          this.utils.userCreatedOnThisCF = false;
        } else {
          /* If the user is new (created on this cf) we just update it's email */
          const updatedUser = await updateUser(
            this.utils.apiToken,
            this.userId,
            {
              email,
            }
          );
          this.dataHandlers.user = updatedUser;
        }
      } else {
        const userRes = await findOrCreateUser(this.utils.apiToken, email, {
          status: UserStatus.PENDING,
          createdAt: new Date(),
          language: this.language,
        });
        this.userId = userRes.user._id;
        this.dataHandlers.user = userRes.user;
        this.utils.userCreatedOnThisCF = userRes.newUser;
      }
    }
    this.status = ContributionStatus.PENDING;
    if (this.utils.dbEntryCreated) {
      await this.updateEntryOnDatabase(this._id, {
        userId: this.userId,
        status: this.status,
      });
    } else {
      // eslint-disable-next-line no-unused-vars
      const { utils, dataHandlers, mapUtils, ...contribution } = this;

      await this.createEntryOnDatabase(contribution, utils.apiToken);
    }

    await this.getConsents();
    await this.getDemographics();
    return { user: this.dataHandlers.user, success: true };
  }

  async assignConsents(consents: Consent[]) {
    this.dataHandlers.consents = consents;
  }
  // assignDemographics?

  async getDemographics() {
    const demographics = await getDemographicsByUserByProject(
      this.userId,
      this.projectId,
      this.utils.apiToken
    );

    if (demographics) {
      this.demographicsId = demographics._id;
      this.dataHandlers.demographics = demographics;
      await this.updateEntryOnDatabase(this._id, {
        demographicsId: demographics._id,
      });
      return;
    }
  }

  async getConsents() {
    const consents = await getConsentsByEmailProject(
      this?.dataHandlers?.user?.email,
      this?.dataHandlers?.project?.id,
      this.utils.apiToken
    );
    this.dataHandlers.consents = consents || [];
  }

  async createEntryOnDatabase(
    contribution: Contribution<'comment'>,
    apiToken: string
  ) {
    this.checkFingerprint();
    const data = await createCommentContribution(contribution, apiToken);
    this.utils.dbEntryCreated = true;
    return data as {
      message: string;
      success: boolean;
      data: {
        _id: string;
      };
    };
  }

  async checkFingerprint() {
    //
  }

  async updateContribution(updates: Partial<Contribution<'comment'>>) {
    Object.assign(this, updates);

    await this.updateEntryOnDatabase(this._id, updates);
  }

  /**
   *
   * @param _id the contribution id
   * @param content the contribution updates
   * @returns
   */
  async updateEntryOnDatabase(
    _id: string,
    content: Partial<Contribution<'comment'>>
  ) {
    const {
      utils: { apiToken },
      ...contribution
    } = this;

    const data = await updateAcornContribution(
      contribution._id,
      content,
      apiToken
    );
    return data as boolean;
  }

  /**
   *
   * @param answers the (whole) answers object, e.g. { 'df2e2e8289eu9df6e87e6' : 'a1', 'df2e2e8289eu98qa7987dsa': 'a2' }
   */
  async assignAnswer({
    answers,
    answersPopulated,
  }: {
    answers: Answers;
    answersPopulated: Array<PopulatedAnswer>;
  }) {
    this.answers = answers;
    this.answersPopulated = answersPopulated;
    const {
      utils: { apiToken },
      mapUtils,
      dataHandlers,
      ...contribution
    } = this;
    if (this.utils.dbEntryCreated) {
      await this.updateEntryOnDatabase(this._id, {
        answers: this.answers,
        answersPopulated: this.answersPopulated,
      });
    } else {
      await this.createEntryOnDatabase(contribution, apiToken);
    }
  }

  async assignVoiceAnswers(voiceAnswers: Record<string, string>) {
    this.voiceAnswers = voiceAnswers;
    const {
      utils: { apiToken },
      mapUtils,
      dataHandlers,
      ...contribution
    } = this;
    if (this.utils.dbEntryCreated) {
      await this.updateEntryOnDatabase(this._id, {
        voiceAnswers,
      });
    } else {
      await this.createEntryOnDatabase(contribution, apiToken);
    }
  }

  async setSentimentQuestionId(questionId: string) {
    this.mapUtils.sentimentQuestionId = questionId;
  }

  async setLocation(coordinates) {
    this.location = {
      ...coordinates,
      type: 'Point',
    };

    if (this.utils.dbEntryCreated)
      await this.updateContribution({ location: this.location });
  }

  async initFromExistentContribution(
    contribution: Partial<Contribution<'comment'>>
  ) {
    Object.assign(this, contribution);
    if (contribution.userId) {
      await this.assignUser({ _id: contribution.userId } as User);
    }
    await this.getConsents();
    await this.getDemographics();
    this.utils.dbEntryCreated = true;
    return this;
  }

  async saveMapContributionPg({
    mapPageContent,
    saveMapAnswersPopulatedOnPostgres = false,
  }) {
    try {
      const [lat, lng] = this.location.coordinates;

      await this.updateContribution({
        newGeocode: await reverseGeocode(Number(lat), Number(lng)),
      });

      const ApolloClient = getClient(this.utils.apiToken);

      // TODO: change this when working on the draft pg contribution - use a internal class variable to control this.
      // just like this.utils.dbEntryCreated
      const { data } = await ApolloClient.query({
        query: CHECK_POSTGRES_CONTRIBUTION,
        variables: {
          contributionId: this._id,
        },
      });

      const { pivotQuestion } =
        mapPageContent.geolytixWorkspace?.locales?.UK?.layers?.Contributions ||
        {};

      const pivot = getPivotValue(pivotQuestion, this);

      const { exists } = data.checkContributionOnPostgres;

      const pgContributionFields: PgContributionFields = {
        location: this.location,
        pageId: String(this.pageId),
        slug: this.surveySlug,
        sentiment: this.sentiment,
        draft: false,
        projectId: this.projectId,
        userId: this.userId,
        status: this.status,
        deleted: false,
        pivot: pivot || null,
      };

      if (saveMapAnswersPopulatedOnPostgres) {
        const builtAnswersForMapFilters = buildAnswersForMapFilters(this._id, [
          ...this.answersPopulated,
        ]);

        pgContributionFields.metadata = {
          answersPopulated: builtAnswersForMapFilters,
        };
      }

      if (exists) {
        // Updating the contribution on pg
        await ApolloClient.mutate({
          mutation: UPDATE_POSTGRES_CONTRIBUTION,
          variables: {
            id: this._id,
            contribution: pgContributionFields,
          },
        });
      } else {
        // Creating the contribution on pg
        await ApolloClient.mutate({
          mutation: CREATE_POSTGRES_CONTRIBUTION,
          variables: {
            contribution: {
              id: this._id,
              ...pgContributionFields,
            },
          },
        });
      }
    } catch (error) {
      console.error(
        'Error in saveMapContribution @ src/shared/classes/Contribution/Contribution.ts: ',
        error
      );
    }
  }

  async saveDraftOnPostgres() {
    return;
  }

  private async handleUserLanguageChange(language: SupportedLanguages) {
    if (this.dataHandlers.user) {
      const { user } = this.dataHandlers;
      if (user && (!user.language || user.language !== language)) {
        this.dataHandlers.user.language = language;
        await updateUser(this.utils.apiToken, user._id, { language });
      }
    }
  }
  async handleLanguageChange(_id: string, language: SupportedLanguages) {
    /* Contribution handling */
    await this.updateContribution({
      language,
    });
    /* User handling */
    await this.handleUserLanguageChange(language);
  }
  async handleContributionSubmit() {
    await this.updateContribution({
      draft: false,
    });
    /* User handling */ // needs to be done here and on handleLanguageChange (in case of the user being assigned after the language change)
    await this.handleUserLanguageChange(this.language);

    /* Profanity words moderation */
    await moderateCommentGql({
      apiToken: this.utils.apiToken,
      contributionId: this._id,
    });

    /* Voice answers upload */
    if (this.voiceAnswers) {
      await uploadVoiceAnswersToS3({
        apiToken: this.utils.apiToken,
        voiceAnswers: this.voiceAnswers,
        contributionId: this._id,
      });
    }
  }
}
