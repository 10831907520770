import * as React from 'react';
import { useRouter } from 'next/router';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/node';
import { GamingText } from 'Organisms';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import {
  useUser,
  useProject,
  useAnalytics,
  MixpanelEventTypes,
  useMap,
  useProposalContext,
} from 'Client/utils/hooks';
import {
  parseSyntaxValidationResult,
  validateEmail,
} from 'Client/utils/validators';
import { handleEmailSubmit } from 'Client/services/proposals';
import { syntaxValidationRequest } from 'Client/services/validation';
import { getBlockedData } from 'Client/services/user';
import { Answers, EmailPanel } from 'Pages/proposals';
import { Button } from 'Molecules';
import { getInitEmailInput } from 'Client/utils/contributionFlow';
import {
  CONTRIBUTION_SESSION_ITEM,
  getLocalItem,
} from 'Client/utils/localstorage';
import { addNewQueryParam, changeRouteKeepParams } from 'Client/utils/url';
import { areNoQuestionsAnswered } from 'Client/pages/proposals/utils';
import useTrackEmailValidation from 'Client/utils/hooks/useAnalytics/useTrackEmailValidation';
import { LoadingButtonStates } from 'Atoms';
import LoadingScreen from 'Client/components/molecules/LoadingScreen/LoadingScreen';
import { IImagePollAnswer } from 'Client/pages/proposals/types';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { SupportedLanguages } from 'Client/constants/languages';
import {
  BottomNavigation,
  BackNavigationAction,
  NextNavigationButton,
  SubmitButton,
} from './Footer.styles';
import { FooterProps } from './types';

const Footer: React.FC<FooterProps> = ({
  slug,
  onSaveDraftComment,
  onSubmitComment,
  proposalHasQuestions,
  hideQuestion,
  questions,
}: FooterProps) => {
  const { i18n, t } = useTranslation();
  const router = useRouter();
  const { user } = useUser();
  const { apiToken } = useUtils();
  const { trackEvent } = useAnalytics();
  const trackEmailValidation = useTrackEmailValidation();
  const project = useProject();
  const [
    {
      answers,
      voiceAnswers,
      currentStep,
      totalSteps,
      signupEmail,
      contributionId,
      content,
    },
    dispatch,
  ] = useProposalContext();
  console.log('🚀 ~ answers:', answers);

  const {
    dispatch: mapDispatch,
    state: { contributionFlow: mapContributionFlow },
  } = useMap();
  const [openEmailPanel, setOpenEmailPanel] = React.useState(false);
  const [emailInputValue, setEmailInputValue] = React.useState('');
  const [emailValidationStatus, setEmailValidationStatus] =
    React.useState(null);
  const [submitWithForce, setSubmitWithForce] = React.useState(false);
  const [isHandlingSubmit, setIsHandlingSubmit] = React.useState(false);
  const localStorageUserId = getLocalItem(CONTRIBUTION_SESSION_ITEM);
  const [nextActionPerformed, setNextActionPerformed] = React.useState(false);

  const isEmbed = router.query?.embed === 'true';

  React.useEffect(() => {
    const initEmailInput = async () => {
      const existingEmail = await getInitEmailInput(
        localStorageUserId,
        signupEmail,
        apiToken
      );
      if (existingEmail && existingEmail !== 'undefined') {
        setEmailInputValue(existingEmail.toLowerCase());
        saveEmailToContext(existingEmail.toLowerCase());
      }
    };

    if (emailInputValue === '' && (signupEmail || localStorageUserId)) {
      initEmailInput();
    }
  }, [emailInputValue, localStorageUserId, signupEmail]);

  // on all next clicks, if the email input is not an empty string
  const validateAndSaveEmail = async (contrId: string | undefined) => {
    if (!emailIsAlreadyValidated()) {
      // Check against the basic email validation regex
      if (!validateEmail(emailInputValue)) {
        setEmailValidationStatus({
          type: 'error',
          message: t('This does not appear to be a valid email address'),
        });
        setOpenEmailPanel(true);

        return false;
      }

      // Unless user wants to force submit in spite of previous warning we call
      // external validation api and handle the result
      const externalValidationStatus = submitWithForce
        ? null
        : await syntaxValidationRequest({
            data: emailInputValue,
          }).then(parseSyntaxValidationResult(t));

      trackEmailValidation(emailInputValue, externalValidationStatus, {
        contributionId: contrId,
      });

      // If there is a result from the validation api call, set the status message
      // and allow user to force submit if type is not 'error'
      if (externalValidationStatus) {
        setEmailValidationStatus(externalValidationStatus);
        setSubmitWithForce(externalValidationStatus.type === 'warning');
        setOpenEmailPanel(true);

        return false;
      }
    }

    setSubmitWithForce(false);
    saveEmailToContext(emailInputValue.toLowerCase());

    await handleEmailSubmit({
      emailValue: emailInputValue.toLowerCase(),
      language: i18n.language as SupportedLanguages,
      contributionId: contrId,
      project,
      apiToken,
    });

    trackEvent(MixpanelEventTypes.ADDED_EMAIL, {
      path: router.asPath,
      step: `${currentStep}/${totalSteps}`,
    });

    return true;
  };

  // If the email has been saved then it has already passed validation - as long
  // as saved email matches email input current value we can be confident...
  const emailIsAlreadyValidated: () => boolean = () => {
    const data = mapContributionFlow
      ? mapContributionFlow.data.userEmail
      : signupEmail;

    return data && emailInputValue === data;
  };

  const saveEmailToContext = (emailValue: string) => {
    if (mapContributionFlow) {
      mapDispatch({
        type: 'SET_COMMENT_DATA',
        payload: { userEmail: emailValue },
      });
    }
    dispatch({
      type: PROPOSAL_ACTION_TYPES.SET_SIGNUP_EMAIL,
      signupEmail: emailValue,
    });
  };

  const canProceed = async (contrId: string, isGaming: boolean) => {
    if (isGaming) {
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_GAMING_BANNER_IS_OPEN,
        isOpen: true,
      });
      setOpenEmailPanel(false);
      return true; // should be false?
    }
    if (emailInputValue !== '') {
      return await (async () => {
        const { blocked } = await getBlockedData({
          email: emailInputValue,
          id: null,
          endpointOnGql: project.features.userEndpointsOnGql,
          apiToken,
        });
        if (blocked) {
          setOpenEmailPanel(true);
          setEmailValidationStatus({
            type: 'error',
            message: <GamingText />,
          });
          return false;
        }
        const isValidEmail = await validateAndSaveEmail(contrId);
        if (!isValidEmail) return false;
        return true;
      })();
    }
    return true;
  };

  console.log('loading', nextActionPerformed, '||', isHandlingSubmit);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (nextActionPerformed || isHandlingSubmit) {
      if (emailValidationStatus?.type !== 'error') {
        return setLoading(true);
      }
    }
    setLoading(false);
  }, [nextActionPerformed, isHandlingSubmit, emailValidationStatus]);

  const handleNextClick = async (href: string) => {
    console.time(
      `ContributionFlow - [1] - handleNextClick - step ${currentStep}`
    );
    const { contrId, isGaming } = await onSaveDraftComment();
    const proceed = await canProceed(contrId, isGaming);
    if (proceed) {
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_CURRENT_STEP,
        currentStep: currentStep + 1,
      });
      setOpenEmailPanel(false);
      let newUrl = '';
      if (contrId) {
        const params = addNewQueryParam(router.asPath, 'cid', contrId);
        newUrl = `${href}${params}`;
      } else {
        newUrl = changeRouteKeepParams(router.asPath, href);
      }
      console.timeEnd(
        `ContributionFlow - [1] - handleNextClick - step ${currentStep}`
      );
      router.push(newUrl);
    }
  };

  const handleBackClick = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch({
      type: PROPOSAL_ACTION_TYPES.SET_CURRENT_STEP,
      currentStep: currentStep - 1,
    });
    router.back();
  };

  const handleSubmitComment = async (e: React.MouseEvent) => {
    console.time('ContributionFlow - [1] - handleSubmitComment');
    console.time('ContributionFlow - [1] - handleSubmitComment - No email');
    console.time('ContributionFlow - [1] - handleSubmitComment - Blocked user');

    setIsHandlingSubmit(true);
    // clicked from the confirmation page /proposals/{slug}/confirm
    // or from the last proposal step if skipConfirmPageCF is true
    e.preventDefault();
    if (emailInputValue === '') {
      // if email is left empty, submit comment
      const result = await onSubmitComment();
      if (result && result.isGaming) {
        dispatch({
          type: PROPOSAL_ACTION_TYPES.SET_GAMING_BANNER_IS_OPEN,
          isOpen: true,
        });
      }
      console.timeEnd(
        'ContributionFlow - [1] - handleSubmitComment - No email'
      );
      return;
    }
    // validateAndSaveEmail() needs to be called again on submit
    // in order to make sure the email is stored in the cookie in one-step proposals,
    // or when the email is changed right in the confirm page
    const { blocked } = await getBlockedData({
      email: emailInputValue,
      id: null,
      endpointOnGql: project.features.userEndpointsOnGql,
      apiToken,
    });
    if (blocked) {
      setOpenEmailPanel(true);
      setEmailValidationStatus({
        type: 'error',
        message: <GamingText />,
      });
      setIsHandlingSubmit(false);
      console.timeEnd(
        'ContributionFlow - [1] - handleSubmitComment - Blocked user'
      );
      return;
    }
    const proceed = await validateAndSaveEmail(contributionId);
    if (proceed) {
      setOpenEmailPanel(false);
      const result = await onSubmitComment(emailInputValue);
      if (result && result.isGaming) {
        dispatch({
          type: PROPOSAL_ACTION_TYPES.SET_GAMING_BANNER_IS_OPEN,
          isOpen: true,
        });
      }
    }
    setIsHandlingSubmit(false);
    console.timeEnd('ContributionFlow - [1] - handleSubmitComment');
  };

  const handleEmailInputChange = (value: string) => {
    if (value !== emailInputValue) {
      setSubmitWithForce(false);
      setEmailValidationStatus(null);
      setEmailInputValue(value.toLowerCase());
    }
  };

  React.useEffect(() => {
    if (emailValidationStatus?.type !== 'error') {
      setNextActionPerformed(false);
    }
  }, [router?.asPath, emailValidationStatus]);

  const checkBranchingLogicRule = (rule) => {
    console.log('checkBranchingLogicRule - rule: ', rule);
    console.log('checkBranchingLogicRule - answers: ', answers);

    const questionId = Object.keys(rule)[0];
    const answer = answers[questionId];

    if (!String(answer).length) {
      console.info('Empty answer - skipping branching logic...');

      return false;
    }

    const questionType =
      questions?.find((question) => question.id === questionId)?.type || '';

    console.log('checkBranchingLogicRule - questionId: ', questionId);

    console.log('checkBranchingLogicRule - answer: ', answer);
    console.log('checkBranchingLogicRule - questionType: ', questionType);

    // if there is a rule for that question:
    if (rule[questionId]) {
      /*
        rule[questionId] is an object in this format:
        { 'condition': 'value' }

        e.g:
        const questionId = {
            equals: 'Sad',
          };
      */
      const ruleCondition = Object.keys(rule[questionId])[0] as string;
      const ruleExpectedValue = Object.values(rule[questionId])[0] as string;

      console.log(
        'checkBranchingLogicRule - ruleExpectedValue: ',
        ruleExpectedValue
      );

      console.log({
        questionId,
        answer,
        ruleCondition,
        ruleExpectedValue,
      });

      let result = false;

      switch (questionType) {
        // Type label means a textpoll that multiple answers are allowed
        // Type textpoll means a textpoll that only one answer is allowed
        case 'label':
        case 'textpoll': {
          switch (ruleCondition) {
            case 'equal':
            case 'contains': {
              result = (answer as string[]).includes(ruleExpectedValue);
              break;
            }
            case 'notEqual':
            case 'doesNotContains': {
              result = !(answer as string[]).includes(ruleExpectedValue);
              break;
            }

            default:
              break;
          }

          break;
        }

        case 'imagepoll': {
          const parsedAnswer = Array.isArray(answer)
            ? (answer.map((a) => JSON.parse(String(a))) as IImagePollAnswer[])
            : [JSON.parse(String(answer)) as IImagePollAnswer];
          console.log('checkBranchingLogicRule - parsedAnswer: ', parsedAnswer);

          switch (ruleCondition) {
            case 'equal':
            case 'contains': {
              result = parsedAnswer.some((answer) =>
                answer.value.includes(ruleExpectedValue)
              );
              break;
            }
            case 'notEqual':
            case 'doesNotContains': {
              result = !parsedAnswer.some((answer) =>
                answer.value.includes(ruleExpectedValue)
              );
              break;
            }

            default:
              break;
          }

          break;
        }

        case 'rating-number':
        case 'smilie': {
          switch (ruleCondition) {
            case 'equal': {
              result = answer === ruleExpectedValue;
              break;
            }
            case 'notEqual': {
              result = answer !== ruleExpectedValue;
              break;
            }

            default:
              break;
          }

          break;
        }

        default:
          break;
      }

      return result;
    }

    return false;
  };

  const handleBranchingLogic = () => {
    let finalResult = {
      goToStep: `step${currentStep + 1}`,
    };

    try {
      console.log('handleSkipLogic()');
      console.log('handleSkipLogic() - answers: ', answers);
      console.log('handleSkipLogic() - content: ', content);
      console.log('handleSkipLogic() - currentStep: ', currentStep);

      const stepIndex = currentStep - 1;

      const branchingLogic = content.steps[stepIndex]?.branchingLogic || false;

      console.log('handleSkipLogic() - branchingLogic: ', branchingLogic);

      // if skip logic doesn't exist, return the function
      if (!branchingLogic) return;

      const branchingLogicDefaultRedirect = branchingLogic.redirect;
      const allBranchingLogicRules = branchingLogic.rules;

      finalResult = {
        goToStep: branchingLogicDefaultRedirect?.default
          ? branchingLogicDefaultRedirect?.default
          : `step${currentStep + 1}`,
      };
      let resultWasFound = false;

      // if there are no rules, return the function with the default redirection:
      if (!allBranchingLogicRules.length) return finalResult;

      allBranchingLogicRules.forEach(
        ({ rule: branchingLogicRules, redirect: branchingLogicRedirect }) => {
          if (resultWasFound) return;

          const data = branchingLogicRules.map((rule) => {
            if (typeof rule !== 'string') {
              console.log("rule !== 'string'");

              return checkBranchingLogicRule(rule);
            }
            return rule;
          });

          console.log('handleSkipLogic() - data: ', data);

          const compare = (value1, operator, value3) => {
            if (operator === 'AND') {
              return value1 && value3;
            } else if (operator === 'OR') {
              return value1 || value3;
            }
          };

          const getResult = (data) => {
            while (data.length > 1) {
              console.log(data);
              const value1 = data.shift();
              const operator = data.shift();
              const value3 = data.shift();
              const result = compare(value1, operator, value3);
              data.unshift(result);
            }
            return data;
          };

          const result = getResult(data);
          console.log('handleSkipLogic() - result: ', result);

          // result.shift() to remove the value from the array
          if (result.shift()) {
            console.log(
              'handleSkipLogic() - branchingLogicRedirect.goTo: ',
              branchingLogicRedirect.goTo
            );

            finalResult = { goToStep: branchingLogicRedirect.goTo };

            resultWasFound = true;

            return;
          }

          if (branchingLogicDefaultRedirect.default) {
            console.log(
              'handleSkipLogic() - branchingLogicDefaultRedirect.default: ',
              branchingLogicDefaultRedirect.default
            );

            finalResult = { goToStep: branchingLogicDefaultRedirect.default };

            return;
          }

          console.log('go to the next step');

          finalResult = { goToStep: `step${currentStep + 1}` };

          return;
        }
      );

      return finalResult;
    } catch (error) {
      console.error('handleBranchingLogic() error: ', error);
      captureException(`handleBranchingLogic() error: ${error}`);
      return finalResult;
    }
  };

  const onNextClick = (e: React.MouseEvent) => {
    console.log('onNextClick()');

    console.time('ContributionFlow - [0] - onNextClick - step');
    console.time(
      'ContributionFlow - [0] - onNextClick - last step - no answers'
    );
    console.time(
      'ContributionFlow - [0] - onNextClick - last step - skipConfirmPageCF'
    );
    console.time('ContributionFlow - [0] - onNextClick - last step');

    if (nextActionPerformed) return;

    setNextActionPerformed(true);

    // Branching logic - MVP:
    const branchingLogicResult = handleBranchingLogic();

    console.log('branchingLogicResult: ', branchingLogicResult);

    console.log('currentStep: ', currentStep);

    const step = branchingLogicResult?.goToStep || `step${currentStep + 1}`;

    console.log('step: ', step);

    const userIsInLastStep = currentStep === totalSteps;

    console.log('userIsInLastStep: ', userIsInLastStep);

    if (userIsInLastStep || step === 'endOfTheFlow') {
      if (
        areNoQuestionsAnswered(answers) &&
        areNoQuestionsAnswered(voiceAnswers as Answers)
      ) {
        // if last step, go to /empty-answers if no answers
        const emptyAnswersRoute = changeRouteKeepParams(
          router.asPath,
          `/proposals/${slug}/empty-answers`
        );
        console.timeEnd(
          'ContributionFlow - [0] - onNextClick - last step - no answers'
        );
        router.push(emptyAnswersRoute);
      } else {
        console.timeEnd(
          'ContributionFlow - [0] - onNextClick - last step - skipConfirmPageCF'
        );
        handleSubmitComment(e);
      }
    } else {
      console.timeEnd('ContributionFlow - [0] - onNextClick - step');

      console.log('handleNextClick URL: ', `/proposals/${slug}/${step}`);

      handleNextClick(`/proposals/${slug}/${step}`);
    }
  };

  const isConfirmationPage =
    router.query && router.query.slug && router.query.slug[1] === 'confirm';

  return (
    <BottomNavigation
      showLabels={true}
      data-testid="ProposalPageTemplate-Footer"
    >
      {loading ? <LoadingScreen withLoadingRing={true} /> : null}
      {!user &&
        !project?.features?.hideEmailDrawer &&
        proposalHasQuestions &&
        !hideQuestion && (
          <EmailPanel
            emailProvided={signupEmail}
            emailInputValue={emailInputValue}
            emailValidationStatus={emailValidationStatus}
            onEmailInputChange={handleEmailInputChange}
            openEmailPanel={openEmailPanel}
            toggleOpenEmailPanel={() => setOpenEmailPanel(!openEmailPanel)}
          />
        )}
      {isConfirmationPage ? (
        <SubmitButton
          data-testid="ProposalFooter-submit-button"
          label={t('Next')}
          onClick={handleSubmitComment}
          component={Button}
          disabled={isHandlingSubmit || nextActionPerformed}
        />
      ) : (
        <NextNavigationButton
          data-testid="ProposalFooter-next-button"
          onClick={onNextClick}
          loaderColor="grey"
          resetState={() => {}}
          state={
            loading ? LoadingButtonStates.LOADING : LoadingButtonStates.INITIAL
          }
          disabled={isHandlingSubmit || nextActionPerformed}
        >
          {t('Next')}
        </NextNavigationButton>
      )}
      {!isEmbed && (
        <BackNavigationAction
          data-testid="ProposalFooter-back-button"
          label={t('Back')}
          onClick={handleBackClick}
          icon={<ArrowBackIosIcon fontSize="small" />}
          left
        />
      )}
    </BottomNavigation>
  );
};

export { Footer };
