import { gql } from '@apollo/client';
import { captureException } from '@sentry/node';
import getClient from 'Shared/utils/apollo-client';

export const MODERATE_CONTRIBUTION_MUTATION = gql`
  mutation moderateContribution(
    $moderateContributionInput: ModerateContribution
  ) {
    moderateContribution(
      moderateContributionInput: $moderateContributionInput
    ) {
      message
      status
    }
  }
`;

export const moderateCommentGql = async ({
  apiToken,
  contributionId,
}: {
  apiToken: string;
  contributionId: string;
}) => {
  try {
    const client = getClient(apiToken);
    const { data } = await client.mutate({
      mutation: MODERATE_CONTRIBUTION_MUTATION,
      variables: {
        moderateContributionInput: {
          contributionId: contributionId,
        },
      },
    });
    return data.moderateContribution;
  } catch (error) {
    console.error(error);
    captureException(error);
  }
};
