import * as React from 'react';
import fetch from 'isomorphic-unfetch';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/node';
import { InputLabel, ErrorStatusOrHelperText } from 'Atoms';
import { EmailIcon } from 'Icons';
import {
  validateEmail as validateEmailRegex,
  parseSyntaxValidationResult,
} from 'Client/utils/validators';
import {
  // useAnalytics,
  // MixpanelEventTypes,
  useProposalContext,
} from 'Client/utils/hooks';
import { sendConfirmationEmail } from 'Client/services/email';
import { getBlockedData } from 'Client/services/user';
import {
  // getLocalItem,
  setLocalItem,
  CONFIRMATION_EMAIL_SENT_ITEM,
  // CONTRIBUTION_SESSION_ITEM,
} from 'Client/utils/localstorage';
// import useTrackEmailValidation from 'Client/utils/hooks/useAnalytics/useTrackEmailValidation';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { CommentContribution } from 'Shared/classes/Contribution/Contribution';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { LoadRing } from 'Client/components/molecules';
import { syntaxValidationRequest } from 'Client/services/validation';
import {
  ConfirmButton,
  ContentContainer,
  EmailBlock,
  EmailBottomSection,
  EmailIconWithText,
  EmailSuccessMessage,
  EmailTopSection,
  HeaderContent,
  HeaderText,
  InputContainer,
  LoadingContainer,
  ValidationContainer,
  Wrapper,
} from './ConfirmEmailPageCf3.styles';
import { RoundInput } from '../edit/components/Form';
interface ConfirmEmailPagePropsCf3 {
  contribution: CommentContribution;
  isMap: boolean;
}
export const ConfirmEmailPageCf3: React.FC<ConfirmEmailPagePropsCf3> = ({
  isMap,
  contribution,
}) => {
  const { t, i18n } = useTranslation();
  // const { trackEvent } = useAnalytics();
  const { apiToken } = useUtils();
  const [{ signupEmail }, dispatch] = useProposalContext();
  const [emailInputValue, setEmailInputValue] = React.useState('');
  const [emailValid, setEmailValid] = React.useState(true);
  const [emailSuccess, setEmailSuccess] = React.useState(false);
  const [emailValidationStatus, setEmailValidationStatus] =
    React.useState(null);
  const [isValidating, setIsValidating] = React.useState(false);

  const [submitWithForce, setSubmitWithForce] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  // const localUserId = getLocalItem(CONTRIBUTION_SESSION_ITEM);
  const contributionEmail = contribution?.dataHandlers?.user?.email;

  React.useEffect(() => {
    if (contribution?.dataHandlers?.user?.email === emailInputValue) return;
    setEmailInputValue(contribution?.dataHandlers?.user?.email);
  }, [contribution?.dataHandlers?.user?.email]);

  React.useEffect(() => {
    if (!!emailInputValue && contributionEmail === emailInputValue) {
      return;
    }
    setEmailInputValue(contributionEmail);
  }, [contributionEmail]);

  const handleEmailInputChange = (e: React.ChangeEvent<{ value: string }>) => {
    setSubmitWithForce(false);
    setEmailValidationStatus(null);
    setEmailInputValue(e.target.value?.toLowerCase());
  };

  const userEmailUpdated = async () => {
    try {
      await fetch('/api/demographics/email', {
        method: 'PATCH',
        body: JSON.stringify({
          newEmail: emailInputValue,
          previousEmail: signupEmail,
        }),
      });
    } catch (error) {
      captureException(
        `error in userEmailUpdated @ ConfirmEmailPage.tsx : ${error}`
      );
    }
  };

  // const trackEmailValidation = useTrackEmailValidation();
  const handleResendEmailClick = async () => {
    // if (!emailIsAlreadyValidated()) {
    //   // Check against the basic email validation regex
    //   if (!validateEmail(emailInputValue)) {
    //     return setEmailValidationStatus({
    //       type: 'error',
    //       message: t('This does not appear to be a valid email address'),
    //     });
    //   }

    //   setIsSubmitting(true);

    //   // Unless user wants to force submit in spite of previous warning we call
    //   // external validation api and handle the result
    //   const externalValidationStatus = submitWithForce
    //     ? null
    //     : await syntaxValidationRequest({
    //         data: emailInputValue,
    //       }).then(parseSyntaxValidationResult(t));

    //   trackEmailValidation(emailInputValue, externalValidationStatus, {
    //     contributionId,
    //   });

    //   // If there is a result from the validation api call, set the status message
    //   // and allow user to force submit if type is not 'error'
    //   if (externalValidationStatus) {
    //     setSubmitWithForce(externalValidationStatus.type === 'warning');
    //     setIsSubmitting(false);
    //     return setEmailValidationStatus(externalValidationStatus);
    //   }
    // }

    // gaming check
    const { blocked } = await getBlockedData({
      email: emailInputValue,
      id: null,
      endpointOnGql: true,
      apiToken,
    });

    if (blocked) {
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_GAMING_BANNER_IS_OPEN,
        isOpen: true,
      });
      setIsSubmitting(false);
      return;
    }

    setSubmitWithForce(false);
    setEmailValidationStatus(null);
    setEmailInputValue(emailInputValue.toLowerCase());
    try {
      const contrId = contribution._id;
      const contrType = contribution.type;

      // trackEvent(MixpanelEventTypes.RESENT_EMAIL, {
      //   path: router.asPath,
      // });
      setIsSubmitting(true);
      const userLang = contribution.dataHandlers.user.language || i18n.language;

      const res = await sendConfirmationEmail({
        email: emailInputValue,
        contributionId: contrId,
        contributionType: contrType,
        lang: userLang,
      });
      if (res && res?.user && res?.user?._id) {
        setLocalItem(CONFIRMATION_EMAIL_SENT_ITEM, res.user._id);
      }
      await userEmailUpdated();
      setEmailSuccess(true);
    } catch (error) {
      captureException(
        `error in handleResendEmailClick @ ConfirmEmailPage.tsx : ${error}`
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const getResendButtonDisabled = () => {
    if (isSubmitting) return true;
    if (submitWithForce) return false;
    if (!emailValid) return true;
    if (isValidating) return true;
    return false;
  };

  const emailValidation = async (email?: string) => {
    console.log(
      '📧 Validate email',
      emailInputValue,
      '&&',
      contributionEmail,
      '===',
      emailInputValue,
      '?',
      emailInputValue && contributionEmail === emailInputValue
    );
    // Email block validated the email already OR is an existing user which is already validated too
    if (emailInputValue && contributionEmail === emailInputValue) {
      setEmailValidationStatus(null);
      setEmailValid(true);
      return true;
    }
    const regexValidation = validateEmailRegex(email);
    console.log('📧 ~ emailValidation ~ regexValidation:', regexValidation);

    if (!regexValidation) {
      setEmailValidationStatus({
        type: 'error',
        message: t('This does not appear to be a valid email address'),
      });
      return false;
    }
    const sendGridValidation = await syntaxValidationRequest({
      data: email,
    }).then(parseSyntaxValidationResult(t));
    console.log(
      '📧 ~ emailValidation ~ sendGridValidation:',
      sendGridValidation
    );
    if (sendGridValidation) {
      setEmailValidationStatus(sendGridValidation);
      setSubmitWithForce(sendGridValidation?.type === 'warning');
    } else {
      setEmailValidationStatus(null);
    }
    setEmailValid(sendGridValidation?.type !== 'error');
    return sendGridValidation?.type !== 'error';
  };

  const validateEmail = async (email?: string) => {
    try {
      setIsValidating(true);
      const _email = email || emailInputValue;
      if (!_email) {
        setEmailValidationStatus({
          type: 'error',
          message: t('Please provide an email address'),
        });
        setEmailValid(false);
        return false;
      }
      setEmailValid(false); // prevent continue until request is completed
      const ignoreValidation = !_email || submitWithForce;
      const emailValidationRes = ignoreValidation
        ? true
        : await emailValidation(_email); /* proper validation */
      console.log(
        '📧 ~ validateEmail ~ emailValidationRes:',
        emailValidationRes
      );

      setEmailValid(emailValidationRes);
      if (submitWithForce) {
        setEmailValidationStatus(null);
      }
      if (emailValidationRes) {
        await contribution.assignUser(null, _email);
      }
      console.log(
        '📧 returning',
        emailValidationRes,
        '&&',
        emailValidationStatus?.type !== 'error'
      );
      return Boolean(
        emailValidationRes && emailValidationStatus?.type !== 'error'
      );
    } catch (e) {
      console.error(e);
      captureException(e);
    } finally {
      setIsValidating(false);
    }
  };

  return (
    <Wrapper isMap={isMap}>
      <HeaderContent isMap={isMap}>
        <HeaderText>
          <h1>{t('We’ve sent you an email with a confirmation link')}</h1>
          <p>{t('Don’t forget to confirm your contribution to continue.')}</p>
        </HeaderText>
      </HeaderContent>
      <img src="/static/illustrations/person-email-sent.png" width="150" />
      <ContentContainer>
        {emailSuccess ? (
          <EmailSuccessMessage>
            <p>{t('A new email has been sent, please check your email.')}</p>
          </EmailSuccessMessage>
        ) : (
          <>
            <EmailBlock isMap={isMap}>
              <EmailTopSection>
                <InputLabel>{t('Email')}</InputLabel>
                <InputContainer>
                  <RoundInput
                    data-testid="EmailPage-email-input-field"
                    id="email"
                    placeholder={t('e.g. sam@smith.com')}
                    type="email"
                    value={emailInputValue}
                    onChange={handleEmailInputChange}
                    onBlur={() => validateEmail()}
                    disabled={isValidating || isSubmitting}
                  />
                  {emailValidationStatus && (
                    <ValidationContainer>
                      <ErrorStatusOrHelperText status={emailValidationStatus} />
                      <ConfirmButton onClick={() => validateEmail()}>
                        {t('Check email')}
                      </ConfirmButton>
                    </ValidationContainer>
                  )}
                  {isValidating && (
                    <LoadingContainer>
                      <LoadRing width="18px" height="18px" />
                    </LoadingContainer>
                  )}
                </InputContainer>
              </EmailTopSection>
              <EmailBottomSection>
                <ConfirmButton
                  data-testid="EmailPage-next-button"
                  onClick={() => handleResendEmailClick()}
                  disabled={getResendButtonDisabled()}
                >
                  {t('Resend link')}
                  {/* {(handlingEmailSend || loading) && (
                <RingLoader
                  data-testid="loading-icon"
                  color={'white'}
                  loaderSize={15}
                  width={'unset'}
                  height={'unset'}
                  marginLeft={'0px'}
                />
              )} */}
                </ConfirmButton>
                <EmailIconWithText>
                  <EmailIcon />
                  <b>{t("Didn't get the email?")}</b>
                </EmailIconWithText>
                <p>
                  {t(
                    "Not to worry, just check your email address and we'll resend it."
                  )}
                </p>
              </EmailBottomSection>
            </EmailBlock>
          </>
        )}
      </ContentContainer>
    </Wrapper>
  );
};
