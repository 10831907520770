import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background: ${theme.colors.white[500]};
    padding: 1rem 10%;
  `}
`;

const Button = styled.button<{ hidden: boolean }>`
  ${({ theme, hidden }) => css`
    display: flex;
    padding: 0.75rem 1.5884rem;
    align-items: center;
    gap: 0.75rem;
    border: none;
    border-radius: 0.2978rem;
    font-size: 1rem;
    font-weight: 600;
    ${hidden &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      background: ${theme.colors.grey[500]};
      color: ${theme.colors.white[500]};

      &:hover {
        cursor: not-allowed;
      }
    }
  `}
`;
export const BackButton = styled(Button)`
  ${({ theme }) => css`
    background: ${theme.colors.white[500]};
    color: ${theme.colorMappings.greyButtonLabel};
    &:disabled {
      & > svg {
        path {
          stroke: ${theme.colors.white[500]};
        }
      }
    }
  `}
`;
export const NextButton = styled(Button)`
  ${({ theme }) => css`
    background: ${theme.colors.green['A700']};
    color: ${theme.colors.white[500]};
    &:after {
      margin: 0;
    }
    & > div {
      display: flex;
      align-items: center;
    }
  `}
`;
