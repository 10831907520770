import { gql } from '@apollo/client';
import { Consent } from 'Shared/types/consent';
import getClient from 'Shared/utils/apollo-client';

export const SAVE_CONSENT = gql`
  mutation CreateConsent($consent: JSON) {
    createConsent(consent: $consent)
  }
`;

export const saveConsent = async (
  consent: Consent,
  apiToken: string
): Promise<Consent> => {
  const client = getClient(apiToken);

  const {
    data: { createConsent },
  } = await client.mutate({
    mutation: SAVE_CONSENT,
    variables: {
      consent,
    },
  });
  return createConsent;
};
