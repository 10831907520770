import { captureException } from '@sentry/node';
import { UPLOAD_FILE_TO_S3 } from 'Client/utils/gql/mutations.gql';
import getClient from 'Shared/utils/apollo-client';

export const uploadToS3Gql = async ({ apiToken, variables }) => {
  try {
    const client = getClient(apiToken);
    const { data } = await client.mutate({
      mutation: UPLOAD_FILE_TO_S3,
      variables,
    });
    return data.uploadToS3;
  } catch (e) {
    console.error(e);
    captureException(e);
  }
};
