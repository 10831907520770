import { gql } from '@apollo/client';
import { User } from 'Shared/types';
import getClient from 'Shared/utils/apollo-client';

export const FIND_OR_CREATE_USER = gql`
  mutation findOrCreateUser($findOrCreateUserInput: FindOrCreateUserInput!) {
    findOrCreateUser(findOrCreateUserInput: $findOrCreateUserInput) {
      user {
        _id
        email
        status
        projects
        firstName
        lastName
        firstSeen
        language
      }
      newUser
    }
  }
`;

export const findOrCreateUser = async (
  apiToken: string,
  email: string,
  fields: Partial<User>
) => {
  const client = getClient(apiToken);

  const { data } = await client.mutate({
    mutation: FIND_OR_CREATE_USER,
    variables: {
      findOrCreateUserInput: {
        email: email,
        fields: fields,
      },
    },
  });
  return data.findOrCreateUser;
};
