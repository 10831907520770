import { gql } from '@apollo/client';
import getClient from 'Shared/utils/apollo-client';

const GET_QUESTION_CONTENT_ALL_LANGUAGES = gql`
  query getQuestionContentAllLanguages(
    $questionId: String!
    $projectId: String!
  ) {
    getQuestionContentAllLanguages(
      questionId: $questionId
      projectId: $projectId
    )
  }
`;

export const getQuestionContentAllLanguages = async (
  apiToken: string,
  questionId: string,
  projectId: string
) => {
  try {
    const client = getClient(apiToken);

    const { data } = await client.query({
      query: GET_QUESTION_CONTENT_ALL_LANGUAGES,
      variables: { questionId, projectId },
    });
    return data.getQuestionContentAllLanguages;
  } catch (err) {
    console.log(err);
  }
};
