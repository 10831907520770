import styled, { css } from 'styled-components';
import { LoopIcon as LoopIconBase } from 'Icons';
import { HtmlDescription } from 'Molecules';

export const Wrapper = styled.div`
  padding: 2rem 0 ${({ theme }) => theme.proposals.footer.height};
`;
export const WrapperCf3 = styled.div<{ isMap: boolean }>`
  padding: 2rem 0 ${({ theme }) => theme.proposals.footer.height};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  ${({ isMap }) => `
    ${
      isMap &&
      `
      transform: scale(0.9);
      margin-top: -2.5rem;
      `
    }
  `}
`;

export const IntroDescription = styled(HtmlDescription)`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.grey[700]};
`;

export const LoopIcon = styled(LoopIconBase)`
  ${({ theme }) => css`
    margin-${theme.direction === 'rtl' ? 'left' : 'right'}: 0.5rem;
    color: ${theme.colorMappings.CommonplaceBrand};
  `}
`;

export const FooterTextWrapper = styled.div`
  margin-top: 2rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-end;
  h1,
  p {
    margin: 0;
  }
  h1 {
    font-size: 2.5rem;
    font-weight: 700;
  }
  p {
    font-size: 1.15rem;
  }
  & > div {
    padding: 0.25rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  }
`;

export const ConsentsWrapper = styled.div`
  padding: 0rem 1.5rem;
  max-width: 55rem;
  width: 100%;
`;
