import { opacify } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ isMap: boolean }>`
  ${({ theme, isMap }) => css`
    margin: 0 25%;
    ${theme.breakpoints.down('sm')} {
      margin: 0;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    h1,
    h4,
    p {
      margin: 0;
    }
    ${isMap &&
    `
    margin: unset;
    transform: scale(0.9);
    margin-top: -3.125rem;
    `};
  `}
`;
export const HeaderContent = styled.div<{ isMap: boolean }>`
  ${({ theme, isMap }) => css`
    display: flex;
    align-items: center;
    gap: 2rem;
    width: unset;
    ${isMap || theme.breakpoints.down('sm')} {
      width: 90%;
    }
    text-align: center;
  `};
`;

export const HeaderText = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 37.5rem;

    & > h1 {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 1.3;
    }
    ${theme.breakpoints.down('sm')} {
      width: unset;
      text-align: center;
      gap: 0.5rem;
      & > h1 {
        font-size: 1.8rem;
      }
      & > p {
        font-size: 1rem;
      }
    }
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
`;

export const EmailBlock = styled.div<{ isMap: boolean }>`
  ${({ theme, isMap }) => css`
    width: 70%;
    ${isMap || theme.breakpoints.down('sm')} {
      width: 85%;
    }
    border-radius: 0.375rem;
    background: ${theme.colors.white[500]};
    box-shadow: 0.0625rem 0.1875rem 0.5rem 0rem rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 2rem 2rem 1.5rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  `}
`;

export const ConfirmButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    padding: 0.8125rem 0.5625rem;
    align-items: center;
    justify-content: center;
    gap: 0.4744rem;
    border-radius: 0.1779rem;
    background: ${theme.colors.green['A700']};
    color: ${theme.colors.white[500]};
    border: none;
    width: 100%;
    text-align: center;
    text-wrap: nowrap;

    font-size: 1rem;
    font-weight: 600;
    &:disabled {
      background: ${theme.colors.grey[500]};
      color: ${theme.colors.white[500]};

      &:hover {
        cursor: not-allowed;
      }
    }
    &:hover {
      cursor: pointer;
    }
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      font-size: 0.875rem;
    }
  `}
`;

export const EmailBottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
`;
export const EmailIconWithText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const EmailTopSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  & > label {
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 0rem;
  }
`;
export const InputContainer = styled.div`
  position: relative;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  right: 1.125rem;
  top: calc(1.125rem / 1.5);
`;

export const ValidationContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    padding: 0.5rem 0;
    & > span {
      margin: 0;
      width: 100%;
    }
    & > button {
      width: 8.5rem;
      font-weight: normal;
      font-size: 0.875rem;
      padding: 0.5625rem;
    }

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      & > button {
        width: 100%;
      }
    }
  `}
`;

export const EmailSuccessMessage = styled.div`
  ${({ theme }) => css`
    padding: 1.25rem 3.125rem;
    border-radius: 0.5rem;
    background: ${opacify(-0.85, theme.colors.green['500'])};
  `}
`;
