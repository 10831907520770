import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RoundInput } from 'Client/pages/edit/components/Form';
import { EmailIcon } from 'Atoms/Icons';
import { PRIVACY_POLICY_URL } from 'Client/constants/urls';
import { cpBrandNamePlural } from 'Client/constants/brand';
import { useProject } from 'Client/utils/hooks';
import { titleCase } from 'Client/utils/stringManipulations';
import { FormValidationStatus } from 'Client/types';
import { LoadRing } from 'Client/components/molecules';
import {
  ConfirmButton,
  ConsentCheckboxWrapper,
  ConsentCheckbox,
  ConsentText,
  Content,
  Header,
  HeaderText,
  ValidationStatus,
  Wrapper,
  LoadingContainer,
  InputContainer,
  ValidationContainer,
} from './index.styles';

interface EmailBlockProps {
  email: string;
  setEmail: (email: string) => void;
  confirmEmail?: (email?: string) => void;
  emailValidationStatus: FormValidationStatus;
  onConsentsToggle: (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  disableConsentsCheckbox: boolean;
  consentsCheckboxChecked: boolean;
  processingEmail: boolean;
  isMap: boolean;
}
export const EmailBlock = ({
  email,
  setEmail,
  confirmEmail,
  emailValidationStatus,
  onConsentsToggle,
  disableConsentsCheckbox,
  consentsCheckboxChecked,
  processingEmail,
  ...props
}: EmailBlockProps) => {
  const project = useProject();
  const titleCasedProjectName = titleCase(project.name);

  const { t } = useTranslation();
  return (
    <Wrapper {...props}>
      <Header>
        <img src="/static/illustrations/opinion-urn.png" width="100" />{' '}
        <HeaderText>
          <h4>{t('Validate your response')}</h4>
          <p>
            {t(
              'By adding your email, you’ll ensure that your contribution will be published and included in the evaluation'
            )}
          </p>
        </HeaderText>
      </Header>
      <Content>
        <div>
          <EmailIcon />
          <b>
            {t("We'll send you an email with a link to confirm your response")}
          </b>
        </div>
        <InputContainer>
          <RoundInput
            disabled={processingEmail}
            type="text"
            data-testid="cf-email-block-input"
            id="email-block-input"
            placeholder={t('Email address')}
            onChange={({ target }) => {
              setEmail(target.value);
            }}
            value={email}
          />
          {processingEmail && (
            <LoadingContainer>
              <LoadRing width="18px" height="18px" />
            </LoadingContainer>
          )}
        </InputContainer>
        {emailValidationStatus && (
          <ValidationContainer>
            <ValidationStatus
              status={emailValidationStatus}
              data-testid="email-validation-status"
            />
            <ConfirmButton
              data-testid="cf-email-block-button"
              onClick={() => confirmEmail()}
              disabled={processingEmail}
              isMap={props.isMap}
            >
              {t('Check email')}
            </ConfirmButton>
          </ValidationContainer>
        )}
        <ConsentText
          data-testid="consent-checkbox-container"
          isMap={props.isMap}
        >
          <ConsentCheckboxWrapper isMap={props.isMap}>
            <ConsentCheckbox
              onChange={onConsentsToggle}
              disabled={disableConsentsCheckbox}
              checked={consentsCheckboxChecked}
            />
            <Trans>
              <p>
                Receive updates and replies about{' '}
                <span>{titleCasedProjectName}</span> and hear about{' '}
                {cpBrandNamePlural} in your area. We’ll never publish your
                personal data. See our{' '}
                <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                  privacy policy
                </a>{' '}
                and{' '}
                <a href="/teams" target="_blank" rel="noreferrer">
                  organisations involved
                </a>
                .
              </p>
            </Trans>{' '}
          </ConsentCheckboxWrapper>
        </ConsentText>
      </Content>
    </Wrapper>
  );
};
