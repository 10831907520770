import { Types } from 'mongoose';
import isEqual from 'lodash.isequal';
import { captureException } from '@sentry/node';
import { SupportedLanguages } from 'Client/constants/languages';
import {
  DemographicsAcorn,
  DemographicsAnswerPopulated,
  PseudoDemographics,
} from 'Shared/types/demographics';
import { Consent } from 'Shared/types/consent';
import { ProjectProps, User } from 'Shared/types';
import { updateAcornDemographics } from 'Client/services/demographics/updateAcornDemographics.gql';
import { createAcornDemographics } from 'Client/services/demographics/createAcornDemographics.gq';
import { createPseudoDemographics } from 'Client/services/pseudoDemographics/createPseudoDemographics.gql';
import { updatePseudoDemographics } from 'Client/services/pseudoDemographics/updatePseudoDemographics.gql';
import { getDemographicsByUserByProject } from 'Client/services/demographics/getDemographicsByUserByProject.gql';
import { getPseudoByDemographicsId } from 'Client/services/pseudoDemographics/getPseudoByDemographicsId.gql';
import { SPECIAL_CATEGORY_DATA } from 'Client/constants/demographics';
import { NewDemographicsGeocode } from 'Shared/types/geocode';
import { CommentContribution } from '../Contribution/Contribution';

interface DemographicsConstructorProps {
  project: ProjectProps;
  user: User;
  contributionClass: CommentContribution;
  consents: Consent[];
  apiToken: string;
}
export class DemographicsClass implements DemographicsAcorn {
  _id: string;
  answersPopulated: DemographicsAnswerPopulated[];
  createdAt: Date;
  updatedAt: Date;
  language: SupportedLanguages;
  newGeocodes: NewDemographicsGeocode[];
  projectId: string;
  userId: string;
  dataHandlers: {
    project: ProjectProps;
    user: User;
    consents: Consent[];
    contribution: CommentContribution;
    pseudoDemographics: PseudoDemographics;
  };
  utils: {
    apiToken: string;
    dbEntryCreated: boolean;
  };

  constructor({
    project,
    user,
    contributionClass,
    consents,
    apiToken,
  }: DemographicsConstructorProps) {
    this._id = String(new Types.ObjectId());
    this.answersPopulated = [];
    this.createdAt = new Date();
    this.updatedAt = new Date();
    this.language = user?.language || 'en-GB';
    this.newGeocodes = null;
    this.projectId = project?._id;
    this.userId = user?._id;
    this.dataHandlers = {
      project: project,
      user: user,
      contribution: contributionClass,
      consents: consents,
      pseudoDemographics: {
        _id: String(new Types.ObjectId()),
        answersPopulated: [],
        created: new Date(),
        hash: '',
      },
    };
    this.utils = {
      apiToken,
      dbEntryCreated: false,
    };
    if (!user) {
      captureException(
        'Attempted to create a demographics class without a user',
        {
          extra: {
            project,
            user,
            consents,
          },
        }
      );
      throw new Error(
        'Attempted to create a demographics class without a user'
      );
    }
    if (!project) {
      captureException(
        'Attempted to create a demographics class without a project',
        {
          extra: {
            project,
            user,
            consents,
          },
        }
      );
      throw new Error(
        'Attempted to create a demographics class without a project'
      );
    }

    // this.checkExistentDemographics();
  }

  async checkExistentDemographics() {
    // contribution.demographicsid || this.userId, projectiD
    // const existentDemographics = {};
    const existentDemographics = await getDemographicsByUserByProject(
      this.userId,
      this.projectId,
      this.utils.apiToken
    );
    if (!existentDemographics) {
      await this.createEntryOnDb();
      await this.assignDemographicsToContribution();
      return;
    }
    Object.assign(this, existentDemographics);
    // this = {
    //   ...this,
    //   ...existentDemographics,
    // }
    // Object.keys(existentDemographics).forEach((key) => {
    //   this[key] = existentDemographics[key];
    // });
    // this._id = existentDemographics._id;
    this.dataHandlers.pseudoDemographics =
      await this.getPseudoForDemographics();

    // Object.assign(this, existentDemographics);
    this.utils.dbEntryCreated = true;
    if (
      existentDemographics &&
      !this.dataHandlers.contribution.demographicsId
    ) {
      await this.assignDemographicsToContribution();
    }

    // check if existent demographics id is different from contribution demographics id and re-assign?

    return {};
  }

  /**
   *
   * @param answers All answers of the demographics (not individual)
   * @param language The language of the answers
   * @returns
   */
  async assignAnswers(answers, language: SupportedLanguages) {
    // if !this.answersPopulated await this.checkExistentDemographics()??
    const [special, nonSpecial] = answers.reduce(
      (acc, answer) => {
        if (answer.question.content.sensitiveType === SPECIAL_CATEGORY_DATA) {
          acc[0].push(answer);
        } else {
          acc[1].push(answer);
        }
        return acc;
      },
      [[], []]
    );
    const nonSpecialAnswers = nonSpecial.map((answer) => {
      const { question, questionAnswer, questionId } = answer;
      const { content, versionId } = question;
      return {
        questionDemographicId: new Types.ObjectId(questionId),
        questionVersionId: new Types.ObjectId(versionId),
        value: questionAnswer,
        questionContent: content,
        otherLanguages: {
          [language]: content,
          // to-do
        },
      };
    });

    if (!isEqual(this.answersPopulated, nonSpecialAnswers)) {
      this.answersPopulated = nonSpecialAnswers;
      await this.updateEntryOnDb({ answersPopulated: nonSpecialAnswers });
    }

    const specialAnswers = special.map((answer) => {
      return answer; // do formating logic
    });

    if (!this.dataHandlers.pseudoDemographics) {
      this.dataHandlers.pseudoDemographics = await this.createPseudoOnDb({
        _id: String(new Types.ObjectId()),
        answersPopulated: specialAnswers,
        created: new Date(),
        hash: '',
      });
      return;
    }

    if (
      !isEqual(
        this.dataHandlers?.pseudoDemographics?.answersPopulated,
        specialAnswers
      )
    ) {
      this.dataHandlers.pseudoDemographics.answersPopulated = specialAnswers;
      await this.updatePseudoOnDb({ answersPopulated: specialAnswers });
    }
  }

  async assignConsents(consents: Consent[]) {
    this.dataHandlers.consents = consents;
  }

  async updatePseudoOnDb(updates: Partial<PseudoDemographics>) {
    const updatedPseudoDemographics = await updatePseudoDemographics(
      this.dataHandlers.pseudoDemographics._id,
      updates,
      this.utils.apiToken
    );
    return updatedPseudoDemographics;
  }

  async createPseudoOnDb(pseudo?: PseudoDemographics) {
    const createdPseudoDemographics = await createPseudoDemographics(
      this._id,
      pseudo || this.dataHandlers.pseudoDemographics,
      this.utils.apiToken
    );
    return createdPseudoDemographics;
  }

  async getPseudoForDemographics() {
    const pseudoByDemographicsId = await getPseudoByDemographicsId(
      this._id,
      this.utils.apiToken
    );
    return pseudoByDemographicsId;
  }
  async updateEntryOnDb(updates: Partial<DemographicsAcorn>) {
    // eslint-disable-next-line no-unused-vars
    const { utils, dataHandlers, ...demographics } = this;

    const updateDemographicsAcorn = await updateAcornDemographics(
      demographics._id,
      { ...updates, updatedAt: new Date() }, // always update 'updatedAt' field
      this.utils.apiToken
    );
    this.utils.dbEntryCreated = true;
    return updateDemographicsAcorn;
  }

  async createEntryOnDb() {
    // eslint-disable-next-line no-unused-vars
    const { utils, dataHandlers, ...demographics } = this;

    const createDemographicsAcorn = await createAcornDemographics(
      demographics,
      this.utils.apiToken
    );

    await this.createPseudoOnDb();
    this.utils.dbEntryCreated = createDemographicsAcorn.acknowledged;
    return createDemographicsAcorn;
  }

  async assignDemographicsToContribution() {
    const contributionClass = this.dataHandlers.contribution;
    contributionClass.demographicsId = this._id;
    contributionClass.dataHandlers.demographics = this;
    return await contributionClass.updateEntryOnDatabase(
      contributionClass._id,
      {
        demographicsId: this._id,
      }
    );
  }

  async initFromExistentDemographics(demographics: DemographicsAcorn) {
    Object.assign(this, demographics);
    this.dataHandlers.pseudoDemographics =
      await this.getPseudoForDemographics();

    Object.assign(this, demographics);
    this.utils.dbEntryCreated = true;
    if (!this.dataHandlers.contribution.demographicsId) {
      await this.assignDemographicsToContribution();
    }
  }

  async handleLanguageChange(language: SupportedLanguages) {
    if (this.language === language) return;
    this.language = language;
    this.dataHandlers.pseudoDemographics.language = language;
    await Promise.all([
      await this.updateEntryOnDb({ language }),
      await this.updatePseudoOnDb({ language }),
    ]);
  }
}
