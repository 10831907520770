import { Types } from 'mongoose';
import { captureException } from '@sentry/node';
import { saveConsent } from 'Client/services/consent/saveConsent.gql';
import { ProjectProps, User } from 'Shared/types';
import { Consent, ConsentHistory } from 'Shared/types/consent';
import { updateConsentGql } from 'Client/services/consent/updateConsent';
import { Consent as ConsentContent } from 'Client/pages/preferences/types';
import { getConsentsByEmailProject } from 'Client/services/consent/getConsents.gql';
import { DemographicsClass } from '../Demographics/Demographics';
import { CommentContribution } from '../Contribution/Contribution';

interface ProjectConsents {
  consents: Consent[];
  dataHandlers: {
    user: User;
    project: ProjectProps;
    demographics: DemographicsClass;
    contribution: CommentContribution;
  };
}

interface ProjectConsentsClassProps {
  user: User;
  project: ProjectProps;
  demographics: DemographicsClass;
  contribution: CommentContribution;
  page: { _id: string; slug: string };
  apiToken: string;
}
/* Do we really need this?
// const getProgrammeForConsent = (consent: Consent) => {
//   const projects = [consent.project];
//   const { programme } = consent;

//   if (consent.type === CONSENT_TYPES.PROJECT_NEWS) {
//     const projectProgrammes = await getProgrammeProjectsFromId(consent.project);

//     projectProgrammes.map((p) => {
//       projects.push(p.id);
//     });
//   }

//   if (programme) {
//     programme
//       .filter((p) => p && p.projectId && p.projectName)
//       .map((p) => {
//         projects.push(p.projectName);
//       });
//   }
// };
*/
export class ProjectConsentsClass implements ProjectConsents {
  consents: Consent[];
  dataHandlers: {
    user: User;
    project: ProjectProps;
    demographics: DemographicsClass;
    contribution: CommentContribution;
  };
  utils: {
    apiToken: string;
  };
  constructor({
    user,
    project,
    demographics,
    contribution,
    apiToken,
  }: ProjectConsentsClassProps) {
    this.consents = [];
    this.dataHandlers = {
      user,
      project,
      demographics,
      contribution,
    };
    this.utils = {
      apiToken: apiToken,
    };

    if (!user) {
      captureException('Attempted to instantiate class without a user', {
        extra: {
          project,
          user,
          demographics,
          contribution,
        },
      });
      throw new Error('Attempted to instantiate class without a user');
    }
    if (!project) {
      captureException('Attempted to instantiate class without a project', {
        extra: {
          project,
          user,
          demographics,
          contribution,
        },
      });
      throw new Error('Attempted to instantiate class without a project');
    }

    if (!demographics || !(demographics instanceof DemographicsClass)) {
      captureException('Attempted to instantiate class without demographics', {
        extra: {
          project,
          user,
          demographics,
          contribution,
        },
      });
      throw new Error('Attempted to instantiate class without demographics');
    }

    if (!contribution || !(contribution instanceof CommentContribution)) {
      captureException('Attempted to instantiate class without contribution', {
        extra: {
          project,
          user,
          demographics,
          contribution,
        },
      });
      throw new Error('Attempted to instantiate class without contribution');
    }
  }
  async checkExistentConsents(): Promise<Consent[]> {
    const existentConsents = await getConsentsByEmailProject(
      this.dataHandlers.user.email,
      this.dataHandlers.project.id,
      this.utils.apiToken
    );
    const consents = [...this.consents, ...existentConsents];
    const uniqueConsents = consents.filter(
      (c, index, self) =>
        index ===
        self.findIndex((t) => t.type === c.type && t.project === c.project)
    );
    this.consents = uniqueConsents;
    return uniqueConsents;
  }

  async createConsent(consent: ConsentContent) {
    const _consent: Consent = {
      ...consent,
      project: this.dataHandlers.project.id,
      projectId: this.dataHandlers.project._id,
      email: this.dataHandlers.user.email,
      userId: this.dataHandlers.user._id,
      demographicsId: this.dataHandlers.demographics._id,
      date: new Date(),
      enabled: consent.enabled,
      type: consent.type,
      history: [
        {
          activity: 'CREATED',
          date: new Date(),
        },
      ],
      _id: String(new Types.ObjectId()),
    };
    // getProgrammeForConsent(_consent); // do we really need this?

    const createConsent = await saveConsent(_consent, this.utils.apiToken);
    this.consents = [...this.consents, _consent]; //savedConsent
    await this.dataHandlers.demographics.assignConsents(this.consents);
    await this.dataHandlers.contribution.assignConsents(this.consents);
    return createConsent;
  }

  async updateConsent(consent: Consent, updates: Partial<Consent>) {
    const newHistory: ConsentHistory = {
      activity: 'UPDATED',
      date: new Date(),
      updates,
    };
    const _consent: Partial<Consent> = {
      enabled: updates.enabled,
      history: [
        ...(this.consents.find((c) => String(c._id) === String(consent._id))
          ?.history || []),
        newHistory,
      ],
    };
    const updateConsent = await updateConsentGql(
      consent._id,
      _consent,
      this.utils.apiToken
    );

    this.consents = this.consents.map((c) =>
      c._id === consent._id ? { ...consent, ..._consent } : c
    );
    await this.dataHandlers.demographics.assignConsents(this.consents);
    await this.dataHandlers.contribution.assignConsents(this.consents);
    return updateConsent;
  }
}
