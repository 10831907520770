import styled, { css } from 'styled-components';
import { ErrorStatusOrHelperText } from 'Atoms';
import { Checkbox } from 'Molecules';

export const Wrapper = styled.div<{ isMap?: boolean }>`
  ${({ theme, isMap }) => css`
    display: flex;
    width: ${isMap ? '90%' : '40rem'};
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border-radius: 0.5rem;
    background: ${theme.colors.white[500]};
    margin: 2rem auto;
    padding: 1.5rem;

    ${theme.breakpoints.down('sm')} {
      width: 80%;
    }
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`;

export const HeaderText = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    & > h4 {
      font-weight: 600;
      font-size: 1.125rem;
      margin: 0;
    }
    & > p {
      font-size: 0.875rem;
      margin: 0;
      color: ${theme.colors.green[400]};
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  & > div {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
  }
`;

export const ConsentText = styled.div<{ isMap: boolean }>`
  ${({ theme, isMap }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start !important;
    gap: 1rem;

    & > div > p {
      width: fit-content;
      font-size: 14px;
      margin: 0;
      & > a {
        text-decoration: underline;
      }
    }

    ${isMap &&
    `
      flex-direction: column !important;
      justify-content: flex-start !important;
      & > div > p {
        width: 90%;
        font-size: 12px;
      }
      gap: 0.8rem;
    `}

    ${theme.breakpoints.down('sm')} {
      flex-direction: column !important;
      justify-content: flex-start !important;
      & > div > p {
        width: 90%;
        font-size: 12px;
      }
      gap: 0.8rem;
    }
    .MuiSvgIcon-root {
      fill: ${theme.colors.grey[800]} !important;
    }

    .MuiIconButton-root {
      padding: 0;
    }
  `}
`;

export const ConsentCheckboxWrapper = styled.div<{ isMap: boolean }>`
  ${({ theme, isMap }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;
    & > p {
      font-size: 0.875rem;
      margin: 0;
    }
    ${isMap &&
    `
      align-items: center;
    `}
    ${theme.breakpoints.down('sm')} {
      align-items: center;
    }
  `}
`;

export const ConsentCheckbox = styled(Checkbox)<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    &:hover {
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
    }
    svg > path {
      fill: ${disabled ? theme.colors.grey[400] : theme.colors.green[500]};
    }
  `}
`;

export const ConfirmButton = styled.button<{ isMap: boolean }>`
  ${({ theme, isMap }) => css`
    display: flex;
    padding: 0.5625rem;
    align-items: center;
    justify-content: center;
    gap: 0.4744rem;
    border-radius: 0.1779rem;
    background: ${theme.colors.green['A700']};
    color: ${theme.colors.white[500]};
    border: none;
    width: 8.5rem;
    text-align: center;
    text-wrap: nowrap;
    &:hover {
      cursor: pointer;
    }
    ${isMap &&
    `
      width: 100%;
      font-size: 0.875rem;
    `}
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      font-size: 0.875rem;
    }
    &:disabled {
      background: ${theme.colors.grey[500]};
      color: ${theme.colors.white[500]};

      &:hover {
        cursor: not-allowed;
      }
    }
  `}
`;

export const ValidationStatus = styled(ErrorStatusOrHelperText)`
  width: 100%;
  margin-top: 0;
  margin-bottom: 0.625rem;
`;

export const InputContainer = styled.div`
  position: relative;
`;
export const LoadingContainer = styled.div`
  position: absolute;
  right: 18px;
`;

export const ValidationContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    & > span {
      margin: 0;
    }

    ${theme.breakpoints.down('sm')} {
      flex-direction: column !important;
      & > button {
        width: 100%;
      }
    }
  `}
`;
