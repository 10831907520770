import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectStage } from 'Shared/types';
import {
  MixpanelEventTypes,
  useAnalytics,
  useProject,
  useUser,
} from 'Client/utils/hooks';
import {
  parseSyntaxValidationResult,
  validateEmail,
} from 'Client/utils/validators';
import { FormValidationStatus } from 'Client/types';
import { syntaxValidationRequest } from 'Client/services/validation';
import { requestProjectNewsSubscription } from 'Client/services/subscription';
import { GdprInfo, Typography } from 'Atoms';
import { useUtils } from 'Client/utils/hooks/useUtils';
import {
  NewsSubscription,
  SubscriptionButton,
  TextField,
} from './Subscription.styles';
import { SubscriptionProps } from './types';

export const Subscription: React.FC<SubscriptionProps> = ({
  hasNews,
  userHasSubscribed,
}) => {
  const project = useProject();
  const { user } = useUser();
  const { apiToken } = useUtils();
  const { t, i18n } = useTranslation();
  const [emailInput, setEmailInput] = React.useState<string>(
    user?.email || null
  );
  const [subscriptionSucceeded, setSubscriptionSucceeded] =
    React.useState<boolean>(false);
  const [subscriptionError, setSubscriptionError] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [submitWithForce, setSubmitWithForce] = React.useState(false);
  const [emailValidationStatus, setEmailValidationStatus] =
    React.useState<FormValidationStatus<React.ReactNode> | null>(null);

  const showNewsSubscription =
    [ProjectStage.ACTIVE, ProjectStage.COMPLETED].includes(project?.stage) &&
    hasNews &&
    (!user || !userHasSubscribed);
  const showLaunchSubscription =
    project?.stage === ProjectStage.TESTING && !userHasSubscribed;

  const { trackEvent } = useAnalytics();
  const handleSubscription = async () => {
    const email = user ? user.email : emailInput;
    const lang = user?.language || i18n.language;

    if (!email) return;

    if (email === emailInput && !validateEmail(emailInput)) {
      return setEmailValidationStatus({
        type: 'warning',
        message: t('Enter a valid email address'),
      });
    }
    setLoading(true);

    try {
      const externalValidationStatus = submitWithForce
        ? null
        : await syntaxValidationRequest({
            data: email,
          }).then(parseSyntaxValidationResult(t));

      // If there is a result from the validation api call, set the status message
      // and allow user to force submit if type is not 'error'
      if (externalValidationStatus) {
        setSubmitWithForce(externalValidationStatus.type === 'warning');
        setLoading(false);
        return setEmailValidationStatus(externalValidationStatus);
      }

      setSubmitWithForce(false);

      trackEvent(MixpanelEventTypes.NEWS_SUBSCRIPTION, {
        email,
      });

      await requestProjectNewsSubscription({
        email,
        lang,
        project,
        hasNews,
        isTestProject: project?.stage === ProjectStage.TESTING,
        apiToken,
      });
      setSubscriptionSucceeded(true);
      setSubscriptionError('');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSubscriptionSucceeded(false);
      setSubscriptionError(
        'Server error: unable to subscribe right now. Please try again later'
      );
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue: string = String(e.target.value).toLowerCase();
    setEmailInput(emailValue);
    setEmailValidationStatus(null);
  };

  if (subscriptionSucceeded) {
    return (
      <Typography>
        {hasNews
          ? t("Thanks, we'll let you know when news has been added.")
          : t("Thanks, we'll let you know when the project launches.")}
      </Typography>
    );
  }
  return (
    <>
      {(showLaunchSubscription || showNewsSubscription) && (
        <NewsSubscription>
          <TextField
            status={emailValidationStatus}
            handleChange={handleEmailChange}
            placeholder={t('e.g. sam@smith.com')}
            label={t('Email')}
            width="18.75rem"
            disabled={loading}
            value={emailInput}
            aria-label="Newsletter subscription email input field"
          />
          <SubscriptionButton
            onClick={handleSubscription}
            disabled={loading || !emailInput}
            aria-label="Newsletter subscription validate your email"
          >
            {t('Keep me updated')}
          </SubscriptionButton>
          {subscriptionError && (
            <Typography bold color="error">
              {subscriptionError}
            </Typography>
          )}
          <GdprInfo width="30rem" linkBgColorMapping={'brand'} />
        </NewsSubscription>
      )}
    </>
  );
};
