import { uploadToS3Gql } from './uploadToS3.gql';

export const uploadVoiceAnswersToS3 = async ({
  apiToken,
  voiceAnswers,
  contributionId,
}: {
  apiToken: string;
  voiceAnswers: Record<string, string>;
  contributionId: string;
}) =>
  await Promise.all(
    Object.keys(voiceAnswers || {}).map(async (key) => {
      const id = contributionId;
      const blob = await fetch(voiceAnswers[key]).then((r) => r.blob());
      const form = new FormData();
      form.append('file', blob, `${id}_${key}.wav`);
      await uploadToS3Gql({ apiToken, variables: { file: form.get('file') } });
    })
  );
