import * as React from 'react';
import { captureException } from '@sentry/node';
import { Trans, useTranslation } from 'react-i18next';
import { useProject } from 'Client/utils/hooks';
import { Checkbox, LoadRing } from 'Molecules';
import { CircleClose, EmailIcon } from 'Icons';
import {
  validateEmail as validateEmailRegex,
  parseSyntaxValidationResult,
} from 'Client/utils/validators';
import { CommentContribution } from 'Shared/classes/Contribution/Contribution';
import { sendConfirmationEmail } from 'Client/services/email';
import { titleCase } from 'Client/utils/stringManipulations';
import { cpBrandName } from 'Client/constants/brand';
import { PRIVACY_POLICY_URL } from 'Client/constants/urls';
import { syntaxValidationRequest } from 'Client/services/validation';
import { ErrorStatusOrHelperText, RingLoader } from 'Atoms';
import { InputLabel } from './EmailPage.styles';
import {
  ConfirmButton,
  ConsentCheckbox,
  ContentContainer,
  EmailBlock,
  EmailBottomSection,
  EmailIconWithText,
  EmailTopSection,
  HeaderContent,
  HeaderText,
  InfoBlock,
  InfoItem,
  InfoItemContainer,
  InputContainer,
  LoadingContainer,
  ValidationContainer,
  Wrapper,
} from './EmailPageCf3.styles';
import { RoundInput } from '../edit/components/Form';

export interface EmailPageProps {
  contribution: CommentContribution;
  consentsChecked: boolean;
  setConsentsChecked: (checked: boolean) => void;
  changeStep?: (direction: 'backwards' | 'forwards', step?: string) => void;
  isMap: boolean;
  loading: boolean;
}

export const EmailPageCf3: React.FC<EmailPageProps> = ({
  contribution,
  consentsChecked,
  setConsentsChecked,
  changeStep,
  isMap,
  loading,
}) => {
  const project = useProject();
  const titleCasedProjectName = titleCase(project.name);
  const contributionEmail = contribution?.dataHandlers?.user?.email;
  const { t, i18n } = useTranslation();

  const [isValidating, setIsValidating] = React.useState(false);
  const [emailInputValue, setEmailInputValue] = React.useState(
    contributionEmail || ''
  );

  React.useEffect(() => {
    if (!!emailInputValue && contributionEmail === emailInputValue) {
      setEmailValid(true);
      return;
    }
    setEmailInputValue(contributionEmail);
  }, [contributionEmail]);

  const [submitWithForce, setSubmitWithForce] = React.useState(false);
  const [handlingEmailSend, setHandlingEmailSend] = React.useState(false);
  const [emailValid, setEmailValid] = React.useState(true);

  const [emailValidationStatus, setEmailValidationStatus] = React.useState<{
    type: 'error' | 'warning';
    message: React.ReactNode;
  } | null>(null);

  const getConfirmationButtonDisabled = (): boolean => {
    if (submitWithForce) return false;
    if (!emailValid) return true;
    if (isValidating) return true;
    if (loading) return true;
    if (handlingEmailSend) return true;
    return false;
  };
  console.log(
    '🚀 ~ getConfirmationButtonDisabled:',
    getConfirmationButtonDisabled(),
    '?',
    submitWithForce,
    !emailValid,
    isValidating
  );

  const handleEmailInputChange = (e: React.ChangeEvent<{ value: string }>) => {
    setSubmitWithForce(false);
    // setEmailValidationStatus(null);
    setEmailInputValue(e.target.value?.toLowerCase());
  };

  const emailValidation = async (email?: string) => {
    console.log(
      '📧 Validate email',
      emailInputValue,
      '&&',
      contributionEmail,
      '===',
      emailInputValue,
      '?',
      emailInputValue && contributionEmail === emailInputValue
    );
    // Email block validated the email already OR is an existing user which is already validated too
    if (emailInputValue && contributionEmail === emailInputValue) {
      setEmailValidationStatus(null);
      setEmailValid(true);
      return true;
    }
    const regexValidation = validateEmailRegex(email);
    console.log('📧 ~ emailValidation ~ regexValidation:', regexValidation);

    if (!regexValidation) {
      setEmailValidationStatus({
        type: 'error',
        message: t('This does not appear to be a valid email address'),
      });
      return false;
    }
    const sendGridValidation = await syntaxValidationRequest({
      data: email,
    }).then(parseSyntaxValidationResult(t));
    console.log(
      '📧 ~ emailValidation ~ sendGridValidation:',
      sendGridValidation
    );
    if (sendGridValidation) {
      setEmailValidationStatus(sendGridValidation);
      setSubmitWithForce(sendGridValidation?.type === 'warning');
    } else {
      setEmailValidationStatus(null);
    }
    setEmailValid(sendGridValidation?.type !== 'error');
    return sendGridValidation?.type !== 'error';
  };

  const validateEmail = async (email?: string) => {
    try {
      setIsValidating(true);
      const _email = email || emailInputValue;
      if (!_email) {
        setEmailValidationStatus({
          type: 'error',
          message: t('Please provide an email address'),
        });
        setEmailValid(false);
        return false;
      }
      setEmailValid(false); // prevent continue until request is completed
      const ignoreValidation = !_email || submitWithForce;
      const emailValidationRes = ignoreValidation
        ? true
        : await emailValidation(_email); /* proper validation */
      console.log(
        '📧 ~ validateEmail ~ emailValidationRes:',
        emailValidationRes
      );

      setEmailValid(emailValidationRes);
      if (submitWithForce) {
        setEmailValidationStatus(null);
      }
      if (emailValidationRes) {
        await contribution.assignUser(null, _email);
      }
      console.log(
        'returning',
        emailValidationRes,
        '&&',
        emailValidationStatus?.type !== 'error'
      );
      return Boolean(
        emailValidationRes && emailValidationStatus?.type !== 'error'
      );
    } catch (e) {
      console.error(e);
      captureException(e);
    } finally {
      setIsValidating(false);
    }
  };

  const sendCodeClick = async () => {
    try {
      const isEmailValid = await validateEmail();
      console.log('📧 ~ sendCodeClick ~ isEmailValid:', isEmailValid);
      if (!isEmailValid) return;

      setHandlingEmailSend(true);
      await sendConfirmationEmail({
        email: emailInputValue,
        contributionId: contribution._id,
        contributionType: contribution.type,
        lang: contribution?.dataHandlers?.user?.language || i18n.language,
        cf3: true,
      });
      changeStep('forwards');
    } catch (e) {
      captureException(e);
    } finally {
      setHandlingEmailSend(false);
    }
  };

  const handleConsentsToggle = (_e, checked) => {
    setConsentsChecked(checked);
  };
  return (
    <Wrapper isMap={isMap}>
      <HeaderContent isMap={isMap}>
        <HeaderText>
          <h1>
            {t('Provide your email address to confirm your contribution')}
          </h1>
          <p>
            {t(
              'Unlock full participation and enhance your impact by providing your email'
            )}
          </p>
        </HeaderText>
        <img
          src="/static/illustrations/email-confirmed-phone.png"
          width="125"
        />
      </HeaderContent>

      <ContentContainer>
        <EmailBlock isMap={isMap}>
          <EmailTopSection>
            <InputLabel>{t('Email')}</InputLabel>
            <InputContainer>
              <RoundInput
                data-testid="EmailPage-email-input-field"
                id="email"
                placeholder={t('e.g. sam@smith.com')}
                type="email"
                value={emailInputValue}
                onChange={handleEmailInputChange}
                onBlur={() => validateEmail()}
                disabled={isValidating || handlingEmailSend || loading}
              />
              {emailValidationStatus && (
                <ValidationContainer>
                  <ErrorStatusOrHelperText status={emailValidationStatus} />
                  <ConfirmButton onClick={() => validateEmail()}>
                    {t('Check email')}
                  </ConfirmButton>
                </ValidationContainer>
              )}
              {isValidating && (
                <LoadingContainer>
                  <LoadRing width="18px" height="18px" />
                </LoadingContainer>
              )}
            </InputContainer>
            <ConsentCheckbox>
              <Checkbox
                checked={consentsChecked}
                onChange={handleConsentsToggle}
              />

              <Trans>
                <p>
                  Receive news and replies about {titleCasedProjectName} and
                  hear about {cpBrandName} in your area. Read{' '}
                  <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                    {cpBrandName}’s privacy policy
                  </a>{' '}
                  or get in touch with any <a>questions</a>.
                </p>
              </Trans>
            </ConsentCheckbox>
          </EmailTopSection>
          <EmailBottomSection>
            <ConfirmButton
              data-testid="EmailPage-next-button"
              onClick={() => sendCodeClick()}
              disabled={getConfirmationButtonDisabled()}
            >
              {t('Get confirmation link')}
              {(handlingEmailSend || loading) && (
                <RingLoader
                  data-testid="loading-icon"
                  color={'white'}
                  loaderSize={15}
                  width={'unset'}
                  height={'unset'}
                  marginLeft={'0px'}
                />
              )}
            </ConfirmButton>
            <EmailIconWithText>
              <EmailIcon />
              <b>
                {t(
                  "We'll send you an email with a link to confirm your response"
                )}
              </b>
            </EmailIconWithText>
          </EmailBottomSection>
        </EmailBlock>
        <InfoBlock isMap={isMap}>
          <h4>{t('What happens if you don’t give your email? ')}</h4>
          <InfoItemContainer>
            <InfoItem>
              <CircleClose />
              <p>
                {t(
                  "We won't know if you’re a legitimate contributor and your contribution may not be fully considered, limiting your impact on shaping the community."
                )}
              </p>
            </InfoItem>
            <InfoItem>
              <CircleClose />
              <p>
                {t(
                  "Without your email address, other users won't have the chance to engage with or support your ideas."
                )}
              </p>
            </InfoItem>
            <InfoItem>
              <CircleClose />
              <p>
                {t(
                  "You'll miss out on important updates and progress, losing the opportunity to stay informed and involved in decisions that affect your community."
                )}
              </p>
            </InfoItem>
            <InfoItem>
              <CircleClose />
              <p>
                {t(
                  "You'll miss out on future relevant Commonplaces in your local area, limiting your opportunities to participate in initiatives that matter to you."
                )}
              </p>
            </InfoItem>
          </InfoItemContainer>
        </InfoBlock>
      </ContentContainer>
    </Wrapper>
  );
};
