import styled from 'styled-components';

export const ProposalPageContainer = styled.div<{ isMap: boolean }>`
  min-height: ${({ isMap }) => (isMap ? '79vh' : '100vh')};
  margin-top: ${({ isMap }) => (isMap ? '3.5rem' : 0)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div:first-child {
    padding-bottom: 3.125rem;
  }
`;
