import fetch from 'isomorphic-unfetch';
import { DemographicsAnswer } from 'Shared/types/demographics';
import { NewDemographicsGeocode } from 'Shared/types/geocode';

const getGeocodeForDemographicsAnswers = async (
  demographicsAnswers: DemographicsAnswer[]
): Promise<{
  newGeocodes: NewDemographicsGeocode[];
}> => {
  const postcodeAnswers = demographicsAnswers.filter((answer) => {
    const { sensitiveType, type } = answer.question.content;
    return (
      (sensitiveType === 'postcode' || type === 'address') &&
      answer.questionAnswer
    );
  });

  const newGeocodes = await Promise.all(
    postcodeAnswers.map(async (answer) => {
      const { question, questionAnswer } = answer;
      const postcodeIoUrl = `https://api.postcodes.io/postcodes/${questionAnswer}`;
      const json = await fetch(postcodeIoUrl).then((r) => r.json());
      const newGeocode = json?.status === 200 ? json.result : null;

      return {
        questionName: question.content.name,
        data: newGeocode,
      };
    })
  );

  return { newGeocodes };
};

export default getGeocodeForDemographicsAnswers;
