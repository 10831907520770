import * as React from 'react';
import { captureException } from '@sentry/node';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import Draw from 'ol/interaction/Draw';
import { SeoMetaInfo } from 'Atoms';
import {
  useMap,
  usePermissions,
  // useDemographicsContext,
  useProposalContext,
  useUser,
  // useProject,
  // useAnalytics,
  // MixpanelEventTypes,
  // usePermissions,
} from 'Client/utils/hooks';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
// import { checkOnUserLanguage } from 'Client/services/user';
// import { EditModeButton } from 'Pages/edit/components/EditModeButton';
// import {
//   getLocalItem,
//   setLocalItem,
//   CONFIRMATION_EMAIL_SENT_ITEM,
//   CONTRIBUTION_SESSION_ITEM,
// } from 'Client/utils/localstorage';
import { canUserSeeDrafts } from 'Client/services/proposals';
import { CommentContribution } from 'Shared/classes/Contribution/Contribution';
import { DemographicsClass } from 'Shared/classes/Demographics/Demographics';
import { SupportedLanguages } from 'Client/constants/languages';
import { ProjectConsentsClass } from 'Shared/classes/Consents/Consents';
import { Contribution, PopulatedAnswer } from 'Shared/types/contribution';
import { NewProgressBarCf3 } from 'Atoms/NewProgressBarCf3/ProgressBar';
import { syntaxValidationRequest } from 'Client/services/validation';
import {
  validateEmail as validateEmailRegex,
  parseSyntaxValidationResult,
} from 'Client/utils/validators';
import { Masthead } from 'Client/templates/ProposalPages/components/Masthead';
import { Page as PageType, PageTypes } from 'Shared/types/page';
import { MapPageContent } from 'Shared/types/proposalPage';
import { sendConfirmationEmail } from 'Client/services/email';
import { getQuestionContentAllLanguages } from 'Client/services/questions/getOtherLanguagesForQuestion.gql';
import getGeocodeForDemographicsAnswers from 'Client/services/demographics/getDemographicsGeocodes';
import { DemographicsAnswer } from 'Shared/types/demographics';
import { Permissions } from 'Client/constants/permissions';
import {
  ProposalPageProps,
  QuestionValue,
  ProposalStage,
  EmptyAnswersPageProps,
  Answers,
  BlockContentStages,
} from './types';
import { NoPermissionPage } from '../noPermission';
import { SectionWrapperCf3 } from './components/SectionWrapperCf3';
import { DemographicsPageProps } from '../demographics';
import { PreferencesPageProps } from '../preferences';
import { ConfirmEmailPageProps } from '../confirmEmail';
import { NoHeaderEmptyAnswersPage } from './NoHeaderEmptyAnswers';
import { EmailPageCf3 } from '../email/EmailPageCf3';
import { DemographicsPageCf3 } from '../demographics/DemographicsPageCf3';
import { PreferencesPageCf3 } from '../preferences/PreferencesPageCf3';
import { ConfirmEmailPageCf3 } from '../confirmEmail/ConfirmEmailPageCf3';
import { ContributionFlowSectionsCf3 } from '../proposal/ContributionFlowSections';
import { EmailBlock } from './components/EmailBlock';
import { ProposalPageContainer } from './ProposalPageCf3.styles';
import { NavigationButtons } from './components/NavigationButtons';
import { OtherProposalsReel } from './components';

/**
 * Wraps a component to render `NoPermissionPage` if the proposal is in draft stage
 * and the user doesn't have permission to view drafts.
 *
 * @todo Move this to its own file. Due to an import cycle in the login page,
 * `withDraftProtection` gets called before initialisation if it's located on its own
 * file. Once all imports of files in `/pages` have been fixed it should be safe to
 * move this.
 *
 * @param C - The component to wrap.
 * @returns The wrapped component.
 */
export const withDraftProtection = <P extends { stage?: ProposalStage }>(
  C: React.FC<P>
): React.FC<P> =>
  function ComponentWithPermission(props: P) {
    const { user } = useUser();

    const isDraft = props.stage === ProposalStage.DRAFT;
    const allow = !isDraft || canUserSeeDrafts(user);

    return allow ? <C {...props} /> : <NoPermissionPage />;
  };

export interface ProposalPagePropsV3 extends ProposalPageProps {
  demographicsPage: DemographicsPageProps;
  // emailPage?: EmailPageProps;
  preferencesPage: PreferencesPageProps;
  confirmEmailPage: ConfirmEmailPageProps;
  emptyAnswersPage: EmptyAnswersPageProps;
  apiToken: string;
  mapPage?: PageType<MapPageContent>;
  isMap?: boolean;
  sentimentQuestionId?: string;
  proposalPage?: PageType;
}
export const ProposalPageCf3: React.FC<ProposalPagePropsV3> =
  withDraftProtection(
    ({
      proposalId,
      proposalSlug,
      steps,
      showMinutesLeft,
      project,
      demographicsPage,
      preferencesPage,
      confirmEmailPage,
      emptyAnswersPage,
      apiToken,
      isMap = false,
      sentimentQuestionId = '0',
      mapPage,
      proposalPage,
      prefillAnswersFromContribution,
      // emailPage,
      proposalTitle,
      stage,
      questions,
      otherProposals,
      // contributionsNumber,
      // numOfProposalSteps,
      // ...props
    }) => {
      const router = useRouter();
      const { user } = useUser();
      const { i18n, t } = useTranslation();
      const { can } = usePermissions();

      const lang = (router.locale as SupportedLanguages) || 'en-GB';
      console.log('🌐 ~ language:', lang, router.locale);
      const [{ answers: stateAnswers, voiceAnswers }, dispatch] =
        useProposalContext();
      const useMapHook = useMap();
      const pageData = {
        _id: proposalId,
        slug: proposalSlug,
      };
      const [emailInput, setEmailInput] = React.useState<string>(null);
      const [submitWithForce, setSubmitWithForce] = React.useState(false);
      const [emailValid, setEmailValid] = React.useState(true);
      const [processingEmail, setProcessingEmail] = React.useState(false);
      const [consentsChecked, setConsentsChecked] = React.useState(false);
      const [newAnswers, setNewAnswers] = React.useState<{
        answers: Record<string, QuestionValue>;
        answersPopulated: Array<PopulatedAnswer>;
      }>(null);
      const page = mapPage || proposalPage;
      const { doNotAskForPersonalInformation, hideEmailDrawer } =
        project.features || {};
      const { alwaysShowDemographicsPage } = page?.featureFlags || {};
      const [debouncedNewAnswers] = useDebounce(newAnswers, 1000);
      const [userHasAllDefaultConsents, setUserHasAllDefaultConsents] =
        React.useState(false);
      const [emailValidationStatus, setEmailValidationStatus] =
        React.useState(null);
      const [actualStep, setActualStep] = React.useState<string>(
        (router.query.step as string) || 'step1'
      );
      const [answerChanges, setAnswerChanges] = React.useState([]);
      const [debouncedChanges] = useDebounce(answerChanges, 1000);
      const [processingStepChange, setProcessingStepChange] =
        React.useState(false);

      const [contribution, setContribution] =
        React.useState<CommentContribution>(null); // will start from ssr?
      const [demographics, setDemographics] =
        React.useState<DemographicsClass>(null); // from ssr?
      const [projectConsents, setProjectConsents] =
        React.useState<ProjectConsentsClass>(null); // from ssr?

      console.log('🟢 =~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=');
      console.log('🟢 Contribution', contribution);
      console.log('🟢 Demographics', demographics);
      console.log('🟢 ProjectConsents', projectConsents);

      const answersRef = React.useRef({
        answers: {},
        answersPopulated: [],
      });

      const defaultConsents = preferencesPage?.defaultConsents?.consents;
      const customConsents = preferencesPage?.customConsents?.consents;
      const sortedSteps =
        steps?.slice().sort((a, b) => a.order - b.order) || [];

      const answerSteps = sortedSteps.map((_, index) => `step${index + 1}`);
      const personalInfoSteps = doNotAskForPersonalInformation
        ? []
        : ['email', 'demographics', 'preferences', 'confirm-email'];

      const CfOrder = [
        ...answerSteps,
        ...personalInfoSteps,
        'thanks',
        'empty-answers',
      ];
      const currentStep = CfOrder.indexOf(actualStep);
      const showButtons = CfOrder.includes(actualStep);

      const hiddenEmailBlockByFlags =
        hideEmailDrawer || doNotAskForPersonalInformation;

      const emailBlockPosition = CfOrder[0]; // can change in the future via feature flag or depending on the flow (survey etc)

      const showEmailBlock = (): boolean => {
        if (isLastStepOfProposalWithoutQuestions) return false;
        if (hiddenEmailBlockByFlags) return false;
        if (user) return false;
        if (actualStep === emailBlockPosition) return true;
      };

      const isLastStepOfProposalWithoutQuestions =
        currentStep === answerSteps.length - 1 &&
        (!questions || questions.length === 0);

      const hideQuestion =
        !can(Permissions.SEE_QUESTIONS_AFTER_CLOSURE) &&
        (BlockContentStages.includes(stage) ||
          BlockContentStages.includes(project.stage));

      const hideBackButton = () => {
        if (currentStep === 0) return true;
        if (actualStep === 'empty-answers') return true;
        if (isLastStepOfProposalWithoutQuestions) return true;
        return false;
      };

      const hideNextButton = () => {
        if (actualStep === 'confirm-email') return true;
        if (actualStep === 'email') return true;
        if (actualStep === 'empty-answers') return true;
        if (isLastStepOfProposalWithoutQuestions) return true;
        return false;
      };

      const disableNextButton = (): boolean => {
        console.log('📧 ~ disableNextButton?', !emailValid, processingEmail);
        if (!emailValid) return true;
        if (processingEmail) return true;
        if (processingStepChange) return true;
        return false;
      };

      const disableBackButton = () => {
        if (processingStepChange) return true;
        return false;
      };

      const hideButtons = {
        back: hideBackButton(),
        next: hideNextButton(),
      };
      const disableButtons = {
        next: disableNextButton(),
        back: disableBackButton(),
      };

      React.useEffect(() => {
        (async () => {
          if (!contribution) return;
          await contribution.handleLanguageChange(
            contribution._id,

            lang
          );
          if (!demographics) return;
          await demographics.handleLanguageChange(lang);
        })();
      }, [lang]);

      React.useEffect(() => {
        (async () => {
          if (!contribution) return;
          await contribution.assignVoiceAnswers(voiceAnswers);
        })();
      }, [voiceAnswers]);

      React.useEffect(() => {
        if (isMap && contribution) {
          const coordinates = useMapHook?.state?.contributionFlow?.geometry;

          contribution.setLocation(coordinates);
        }
      }, [useMapHook.state.contributionFlow]);

      React.useEffect(() => {
        (async () => {
          let contribution = null;
          if (router.query?.cid) {
            /* Initialize with existing answers if query id present */
            contribution = await prefillAnswersFromContribution(
              router.query.cid as string
            );
          }
          await instantiateContribution(contribution);
        })();
      }, []);

      React.useEffect(() => {
        (async () => {
          if (!contribution?.dataHandlers?.user) return;
          const demographics = await instantiateDemographics();
          await instantiatePreferences(demographics);
        })();
      }, [contribution?.dataHandlers?.user]);

      React.useEffect(() => {
        if (!projectConsents) return;
        /* Check for consents */
        projectConsents?.checkExistentConsents().then((userConsents) => {
          setUserHasAllDefaultConsents(
            userConsents.length && userConsents.every((c) => !!c.enabled)
          );
        });
      }, [contribution?.dataHandlers?.user, projectConsents]);

      React.useEffect(() => {
        if (debouncedNewAnswers) {
          contribution.assignAnswer(debouncedNewAnswers);
        }
      }, [debouncedNewAnswers]);

      React.useEffect(() => {
        /* Answers change 'queue' -> handles all changes 1 after the other to assign it's answersPopulated */
        (async () => {
          for await (const questionId of Object.keys(debouncedChanges)) {
            await handleAnswerChange(questionId, debouncedChanges[questionId]);
            setAnswerChanges((prev) => {
              if (prev[questionId] === debouncedChanges[questionId])
                delete prev[questionId];
              return prev;
            });
          }
        })();
      }, [debouncedChanges]);

      const instantiateContribution = async (
        existentContribution: Partial<Contribution<'comment'>>
      ) => {
        if (contribution) return;

        const ctb = new CommentContribution({
          surveySlug: proposalSlug,
          pageId: proposalId,
          project,
          language: lang,
          apiToken,
          map: isMap,
        });
        if (existentContribution) {
          await ctb.initFromExistentContribution(existentContribution);
          setEmailInput(ctb.dataHandlers.user.email);
        }

        if (user || emailInput) {
          ctb.assignUser(user);
        }

        if (isMap) {
          ctb.setSentimentQuestionId(sentimentQuestionId);
          ctb.setLocation(useMapHook.state.contributionFlow.geometry);
        }

        setContribution(ctb);
      };

      const instantiateDemographics = async () => {
        const demographicsOk =
          demographics && demographics?.userId === contribution?.userId;
        if (demographicsOk || !contribution || !contribution.userId) return;
        const dm = new DemographicsClass({
          project,
          user: contribution.dataHandlers.user,
          contributionClass: contribution,
          consents: contribution.dataHandlers.consents,
          apiToken,
        });
        await dm.checkExistentDemographics();
        setDemographics(dm);
        return dm;
      };

      const instantiatePreferences = async (
        _demographics: DemographicsClass
      ) => {
        const preferencesOk =
          projectConsents &&
          projectConsents?.dataHandlers.user._id === contribution?.userId;
        if (preferencesOk || !contribution || !contribution.userId) return;

        const pc = new ProjectConsentsClass({
          user: contribution.dataHandlers.user,
          project,
          demographics: _demographics,
          contribution,
          page: pageData,
          apiToken,
        });
        await pc.checkExistentConsents();
        setProjectConsents(pc);
        if (consentsChecked) {
          await handleSaveConsents(consentsChecked, pc);
          /* Double check this when continuing draft flow */
        }
      };

      const handleAnswersPopulated = async (
        questionId: string,
        answer: QuestionValue
      ) => {
        const stepQuestionContent = steps
          .map((step) => {
            const question = step.sections.find(
              (q) => String(q?.question?.id) === String(questionId)
            );
            return question?.question;
          })
          .filter(Boolean)[0];

        const _answersPopulated = [
          ...(answersRef?.current?.answersPopulated || []),
        ];
        const existentAnswer = _answersPopulated.find(
          (a) => String(a.questionId) === String(questionId)
        );

        if (existentAnswer) {
          existentAnswer.value = answer;
        } else {
          const otherLanguagesForQuestion =
            await getQuestionContentAllLanguages(
              apiToken,
              questionId,
              project._id
            );
          const answerPopulated: PopulatedAnswer = {
            questionId,
            questionVersionId: stepQuestionContent?.questionVersionId,
            value: answer,
            questionContent: stepQuestionContent,
            otherLanguages: otherLanguagesForQuestion,
          };
          _answersPopulated.push(answerPopulated);
        }

        return _answersPopulated;
      };

      const handleAnswers = (
        questionId: string,
        answer: QuestionValue
      ): Answers => {
        const answers = stateAnswers;
        const answersExist = Object.keys(answers).find(
          (answer) => answers[answer] !== ''
        );

        if (!answersExist) {
          // trigger the first time a question is answered
          // trackEvent(MixpanelEventTypes.ADDING_COMMENT, {
          //   path: router.asPath,
          // });
        }

        const _newAnswers = { ...answers };

        if (
          (Array.isArray(answer) && answer.length === 0) ||
          answer == null ||
          answer === ''
        ) {
          // not ideal, but the whole CF treats empty strings as defaults
          // in future we should move away from the expectation that all answers exist and start as strings
          _newAnswers[questionId] = '';
        } else {
          _newAnswers[questionId] = answer;
        }
        dispatch({
          type: PROPOSAL_ACTION_TYPES.SET_ANSWERS,
          answers: _newAnswers,
        });
        setNewAnswers({
          answers: _newAnswers,
          answersPopulated: newAnswers?.answersPopulated || [],
        });
        return _newAnswers;
      };

      const handleAnswerChange = async (
        questionId: string,
        answer: QuestionValue
      ) => {
        const _answersPopulated = await handleAnswersPopulated(
          questionId,
          answer
        );
        answersRef.current = {
          answers: answersRef.current.answers,
          answersPopulated: _answersPopulated,
        };
        dispatch({
          type: PROPOSAL_ACTION_TYPES.SET_ANSWERS_POPULATED,
          answersPopulated: _answersPopulated,
        });

        setNewAnswers({
          answers: newAnswers.answers,
          answersPopulated: _answersPopulated,
        });
      };

      const handleChange = async (
        questionId: string,
        answer: QuestionValue
      ) => {
        /* Handle the answers right away so the UI matches the data */
        handleAnswers(questionId, answer);
        /* Add answer changes to a 'queue' & debounce */
        return setAnswerChanges({ ...answerChanges, [questionId]: answer });
      };

      const updateStepsOnQuery = (step: string) => {
        let url = isMap
          ? `/${lang}/map/${proposalSlug}?step=${step}`
          : `/${lang}/proposals/v3/${proposalSlug}?step=${step}`;

        if (contribution?.utils?.dbEntryCreated) {
          url += `&cid=${contribution._id}`;
        }

        window.history.replaceState({}, '', url); // to prevent back button from going back to the previous step
      };

      const changeStep = async (
        direction: 'forwards' | 'backwards',
        step?: string
      ) => {
        try {
          setProcessingStepChange(true);
          /* ================= Skip page variables =========== */

          /* Skip if user is already logged in or added email on the drawer */
          const shouldSkipEmailPage =
            !!contribution?.dataHandlers?.user?.email || !!user;
          /* Still shows the demographics page if the one the user has is not confirmed (does not pre-populate[?]) */
          const shouldSkipDemographics = alwaysShowDemographicsPage
            ? false
            : !!contribution?.demographicsId;
          /* Project only has default & user checked them all */
          const shouldSkipPreferences =
            !customConsents.length && userHasAllDefaultConsents;
          /* Not skipping email page when adding email on drawer */
          const shouldSkipConfirmEmail = !!user;
          const hasAnswers = contribution?.answers
            ? !!Object.keys(contribution?.answers).length
            : false;

          /* ================= Next button 'custom' click actions =========== */
          /* Email block step (always the first one for now) click actions */
          if (actualStep === emailBlockPosition) {
            console.log('📧 ~ Validating email on next click');
            const validEmail = await validateEmail();
            console.log('📧 ~ validEmail:', validEmail);
            if (!validEmail) return;
          }
          /* Last step click actions (submit) */
          if (
            actualStep === answerSteps[answerSteps.length - 1] &&
            direction === 'forwards'
          ) {
            const userEmail = contribution?.dataHandlers?.user?.email;
            // move this to the class that calls the api endpoint & do all on backend?
            if (shouldSkipEmailPage && userEmail) {
              await sendConfirmationEmail({
                email: userEmail,
                contributionId: contribution._id,
                contributionType: contribution.type,
                lang:
                  contribution?.dataHandlers?.user?.language || i18n.language,
                cf3: true,
              });
            }
            await contribution.handleContributionSubmit();
          }
          if (actualStep === 'demographics' && direction === 'forwards') {
            if (!demographics?.newGeocodes) {
              const formattedDemographicsAnswers =
                demographics.answersPopulated.map((a) => ({
                  question: {
                    content: a.questionContent,
                  },
                  questionAnswer: a.value,
                })) as DemographicsAnswer[];
              const { newGeocodes } = await getGeocodeForDemographicsAnswers(
                formattedDemographicsAnswers
              );
              await demographics.updateEntryOnDb({
                newGeocodes,
              });
            }
          }
          let newStepIndex = currentStep + (direction === 'forwards' ? 1 : -1);
          let newStep = CfOrder[newStepIndex];

          console.log('🚀 ~ shouldSkipEmailPage:', shouldSkipEmailPage);
          console.log('🚀 ~ shouldSkipDemographics:', shouldSkipDemographics);
          console.log('🚀 ~ shouldSkipPreferences:', shouldSkipPreferences);
          console.log('🚀 ~ shouldSkipConfirmEmail:', shouldSkipConfirmEmail);
          console.log('🚀 ~ newStepIndex:', newStepIndex);
          console.log('🚀 ~ newStep:', newStep);

          /* ================= Handle next click redirection =========== */
          /* If received step as a param should go directly to that step  */
          if (step) {
            updateStepsOnQuery(step);
            return setActualStep(step);
          }
          if (
            !answerSteps.includes(newStep) &&
            !hasAnswers &&
            direction === 'forwards'
          ) {
            newStep = 'empty-answers';
            updateStepsOnQuery(newStep);
            return setActualStep(newStep);
          }

          if (newStep === 'email' && shouldSkipEmailPage) {
            if (direction === 'forwards') {
              newStepIndex++; //demographics
              newStep = CfOrder[newStepIndex];
            }
            if (direction === 'backwards') {
              newStepIndex--; // last step
              newStep = CfOrder[newStepIndex];
            }
          }
          if (
            !contribution.userId &&
            ['demographics', 'preferences'].includes(newStep)
          ) {
            // hard validation - cant go trough demographics and preferences without email/user
            newStep = 'email';
            updateStepsOnQuery(newStep);
            return setActualStep(newStep);
          }
          if (newStep === 'demographics' && shouldSkipDemographics) {
            if (direction === 'forwards') {
              newStepIndex++; // preferences
              newStep = CfOrder[newStepIndex];
            }
            if (direction === 'backwards') {
              newStepIndex--; // email
              if (shouldSkipEmailPage) newStepIndex--; // last step
              newStep = CfOrder[newStepIndex];
            }
          }
          if (newStep === 'preferences') {
            if (shouldSkipPreferences) {
              if (direction === 'forwards') {
                newStepIndex++; // confirm-email
                newStep = CfOrder[newStepIndex];
              }
              if (direction === 'backwards') {
                newStepIndex--; // demographics
                if (shouldSkipDemographics) newStepIndex--; // email
                if (shouldSkipEmailPage && shouldSkipDemographics)
                  newStepIndex--; // last step

                newStep = CfOrder[newStepIndex];
              }
            }
          }
          if (newStep === 'confirm-email') {
            if (shouldSkipConfirmEmail) {
              if (direction === 'forwards') {
                newStepIndex++; // thanks
                newStep = CfOrder[newStepIndex];
              }
              if (direction === 'backwards') {
                newStepIndex--; // preferences
                if (shouldSkipPreferences) newStepIndex--; // demographics
                if (shouldSkipDemographics) newStepIndex--; // email
                if (shouldSkipEmailPage && shouldSkipDemographics)
                  newStepIndex--; // last step
                newStep = CfOrder[newStepIndex];
              }
            }
          }
          if (newStep === 'thanks') {
            if (isMap) {
              const {
                state: { xyz, draftContributionLayer },
                dispatch: mapDispatch,
              } = useMapHook;

              // TODO: test if this is really necessary:
              xyz.map.getInteractions().forEach((interaction) => {
                if (interaction instanceof Draw) {
                  interaction.setActive(false);
                  draftContributionLayer.getSource().clear();
                }
              });

              await contribution.saveMapContributionPg({
                mapPageContent: mapPage.content,
              });

              // TODO: test if this is really necessary:
              mapDispatch({ type: 'CLEAR_ANSWERS' });
              mapDispatch({ type: 'CLEAR_VOICE_ANSWERS' });
              mapDispatch({ type: 'CLEAR_LEFT_PANEL' });
              mapDispatch({
                type: 'SET_CONTRIBUTION_FLOW_STARTED',
                payload: false,
              });
            }
            return router.push(`/cf3/thanks?slug=${proposalSlug}`);
          }
          console.log('🚀 ~ im in :', actualStep, 'going to:', newStep);
          updateStepsOnQuery(newStep);
          setActualStep(newStep);
        } catch (e) {
          captureException(e);
        } finally {
          setProcessingStepChange(false);
        }
      };

      const handleNextClick = async () => {
        changeStep('forwards');
      };

      const handleBackClick = () => {
        changeStep('backwards');
      };

      const handleSaveConsents = async (
        checked: boolean,
        consentsClass: ProjectConsentsClass
      ) => {
        console.log(
          '🚀 ~ handleSaveConsents ~ projectConsents:',
          consentsClass
        );
        if (!checked) return;
        if (!consentsClass) {
          console.log(
            'No project consents instanciated, no user?',
            contribution?.dataHandlers?.user
          );
          return;
        }

        const userConsents = await consentsClass.checkExistentConsents();
        const updatedConsents = defaultConsents.map((consent) => {
          const existingConsent = userConsents.find(
            (c) => c.type === consent.type
          );
          if (!existingConsent) {
            if (checked) {
              consentsClass.createConsent({ ...consent, enabled: checked });
              return { ...consent, enabled: checked };
            }
            return;
          } else {
            consentsClass.updateConsent(existingConsent, {
              enabled: checked,
            });
            return { ...existingConsent, enabled: checked };
          }
        });

        setUserHasAllDefaultConsents(updatedConsents.every((c) => c?.enabled));
      };

      const emailInputChange = (email: string) => {
        if (emailInput === email) return;
        setEmailInput(email.toLowerCase());
        setSubmitWithForce(false);
      };

      const emailValidation = async (email?: string) => {
        const regexValidation = validateEmailRegex(email);
        console.log('📧 ~ emailValidation ~ regexValidation:', regexValidation);

        if (!regexValidation) {
          setEmailValidationStatus({
            type: 'error',
            message: t('This does not appear to be a valid email address'),
          });
          return false;
        }
        const sendGridValidation = await syntaxValidationRequest({
          data: email,
        }).then(parseSyntaxValidationResult(t));
        console.log('📧 ~ sendGridValidation', sendGridValidation);
        if (sendGridValidation) {
          setEmailValidationStatus(sendGridValidation);
          setSubmitWithForce(sendGridValidation?.type === 'warning');
        }
        return sendGridValidation?.type !== 'error';
      };

      const validateEmail = async (email?: string): Promise<boolean> => {
        setEmailValidationStatus(null);
        try {
          setProcessingEmail(true);
          const _email = email || emailInput;
          console.log(`📧 ~ Validating email: ${_email} ....`);

          if (!_email) {
            setEmailValidationStatus(null);
            setEmailValid(true);
            console.log(`📧 ~ No email, returning "valid"`);
            return true;
          }
          setEmailValid(
            false
          ); /* Prevent continue until request is completed */
          const ignoreValidation = !_email || submitWithForce;
          console.log('📧 ~ ignoreValidation:', ignoreValidation);
          const emailValidationRes = ignoreValidation
            ? true
            : await emailValidation(_email);
          console.log(
            '📧 ~ validateEmail ~ emailValidationRes:',
            emailValidationRes
          );

          setEmailValid(emailValidationRes);

          if (emailValidationRes) {
            await contribution.assignUser(null, _email);
          }
          return emailValidationRes;
          // if (submitWithForce || emailValidationRes) {
          //   setEmailValidationStatus(null);
          // }
        } catch (e) {
          console.error(e);
          captureException(e);
          setEmailValidationStatus({
            type: 'error',
            message: t('An error occurred while validating the email address'),
          });
          return false;
        } finally {
          setProcessingEmail(false);
        }
      };

      const disableConsentsCheckbox = () => {
        if (!emailInput) return true;
      };

      return (
        <div>
          <div>
            {!isMap && <Masthead title={pageData.slug} />}
            <NewProgressBarCf3 currentStep={1} totalSteps={10} minToRead={1} />
          </div>
          <ProposalPageContainer
            data-testid="cf3-container"
            data-contribution-id={contribution?._id}
            data-actual-step={actualStep}
            data-user-id={contribution?.userId}
            data-consents={projectConsents?.consents
              .map((c) => c._id)
              .join(', ')}
            data-demographics-id={demographics?._id}
            data-pseudo-demographics-id={
              demographics?.dataHandlers?.pseudoDemographics?._id
            }
            isMap={isMap}
          >
            <div>
              <div data-testid="the-actual-step">{actualStep}</div>
              Contribution: {String(contribution?._id)}
              <br />
              Contribution status: {contribution?.status}
              <br />
              Contribution draft: {String(contribution?.draft)}
              <br />
              User: {String(contribution?.userId || '')}
              <br />
              Demographics: {String(demographics?._id || '')}
              <br />
              PseudoDemographics:{' '}
              {String(
                demographics?.dataHandlers?.pseudoDemographics?._id || ''
              )}
              <br />
              Consents:{' '}
              {projectConsents?.consents.map((c) => String(c._id)).join(', ')}
              <br />
              <SectionWrapperCf3
                currentStep={actualStep}
                possibleSteps={CfOrder}
                isMap={isMap}
              >
                <Head>
                  <meta name="robots" content="noindex" />
                </Head>
                <SeoMetaInfo
                  projectStage={project.stage}
                  projectName={project.name}
                  proposalTitle={proposalTitle}
                  page={PageTypes.PROPOSAL}
                />
                <ContributionFlowSectionsCf3
                  steps={sortedSteps}
                  proposalSlug={proposalSlug}
                  handleChange={handleChange}
                  showMinutesLeft={showMinutesLeft}
                />

                <div id="email" className={proposalSlug}>
                  <EmailPageCf3
                    contribution={contribution}
                    changeStep={changeStep}
                    consentsChecked={consentsChecked}
                    setConsentsChecked={setConsentsChecked}
                    isMap={isMap}
                    loading={processingStepChange}
                  />
                </div>
                <div id="demographics" className={proposalSlug}>
                  <DemographicsPageCf3
                    {...demographicsPage}
                    demographics={demographics}
                    isMap={isMap}
                  />
                </div>
                <div id="preferences" className={proposalSlug}>
                  <PreferencesPageCf3
                    {...preferencesPage}
                    projectConsents={projectConsents}
                    isMap={isMap}
                  />
                </div>
                <div id="confirm-email" className={proposalSlug}>
                  <ConfirmEmailPageCf3
                    {...confirmEmailPage}
                    contribution={contribution}
                    isMap={isMap}
                  />
                </div>
                <div id="empty-answers" className={proposalSlug}>
                  <NoHeaderEmptyAnswersPage
                    {...emptyAnswersPage}
                    changeStep={changeStep}
                  />
                </div>
              </SectionWrapperCf3>
              {isLastStepOfProposalWithoutQuestions && otherProposals && (
                <OtherProposalsReel
                  proposals={otherProposals}
                  project={project}
                  showUnderline={!hideQuestion}
                  isMultiStep={steps?.length > 1}
                />
              )}
              {showEmailBlock() && (
                <EmailBlock
                  data-testid="cf-email-drawer"
                  email={emailInput}
                  setEmail={emailInputChange}
                  confirmEmail={validateEmail}
                  emailValidationStatus={emailValidationStatus}
                  onConsentsToggle={(_e, checked) => {
                    setConsentsChecked(checked);
                  }}
                  consentsCheckboxChecked={consentsChecked}
                  disableConsentsCheckbox={disableConsentsCheckbox()}
                  processingEmail={processingEmail}
                  isMap={isMap}
                />
              )}
            </div>
            {showButtons && (
              <NavigationButtons
                handleNextClick={handleNextClick}
                handleBackClick={handleBackClick}
                disableButtons={disableButtons}
                hideButtons={hideButtons}
                loading={processingStepChange}
              />
            )}
          </ProposalPageContainer>
        </div>
      );
    }
  );
