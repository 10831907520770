import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiTypography from '@material-ui/core/Typography';
import { readableColor } from 'polished';
import { Link as BaseLink } from 'Atoms/Link';
import { editModeMobile } from 'Client/components/theme/utils';
import { getCachedCloudinaryUrl } from 'Client/utils/cloudinary/getCachedCloudinaryUrl';
import { IMAGE_CLOUDINARY_TYPES } from 'Client/utils/cloudinary/types';
import { HtmlDescription } from 'Client/components/molecules';
import {
  HeroCustomBackground,
  ReadableHeroLayout,
  ReadableHeroTypes,
} from './types';

export const Link = styled(BaseLink)`
  width: fit-content;
  margin: 0.6rem 0 1.5rem 0;
  padding: 0 !important;
`;

export const TextIcon = styled(({ iconColor, ...props }) => (
  <MuiTypography {...props} />
))`
  display: flex;
  align-items: center;

  .MuiSvgIcon-root {
    color: black;
    font-size: 1rem;
  }
`;

const getBackgroundImageStyles = (theme, backgroundImage, overlayImage) => {
  return css`
    background-color: ${theme.colorMappings.brand};
    background: url(${getCachedCloudinaryUrl(
        backgroundImage.src,
        IMAGE_CLOUDINARY_TYPES.HERO
      )})
      ${overlayImage && ', rgba(0, 0, 0, 0.3)'};
    ${backgroundImage.customCSS};
    background-blend-mode: multiply;
    ${editModeMobile(
      theme.breakpoints.down('xl'),
      css`
        ${backgroundImage.customCSS.xl};
      `
    )};
    ${editModeMobile(
      theme.breakpoints.down('lg'),
      css`
        ${backgroundImage.customCSS.lg};
      `
    )};
    ${editModeMobile(
      theme.breakpoints.down('md'),
      css`
        ${backgroundImage.customCSS.md};
      `
    )};
    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        ${backgroundImage.customCSS.sm};
      `
    )};
    ${editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        ${backgroundImage.customCSS.xs};
      `
    )};
    color: ${readableColor(theme.colorMappings.brand)};
  `;
};

const getNonBackgroundStyles = (theme) => {
  const background = theme.colorMappings.brand;
  const color = readableColor(background);
  return css`
    width: 100%;
    min-height: 24rem;
    background: ${background};
    color: ${color};
    position: relative;
    display: flex;

    .SVGPattern {
      position: absolute;
      right: 0;
      bottom: 0;
      fill: ${color};
      path,
      ellipse,
      circle {
        stroke: ${color};
      }
    }

    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        .SVGPattern {
          bottom: 0;
          max-width: 30rem;
          path {
            stroke: rgb(255 255 255 / 19%);
          }
        }
      `
    )}
  `;
};

const getBackground = (theme, transparency) => {
  return `
    background-color: ${theme.colorMappings.brand};
    ${transparency && `opacity: 0.9;`}
  `;
};

export const Wrapper = styled.div<{
  backgroundImage: HeroCustomBackground;
  overlayImage: boolean;
  fixedMasthead?: boolean;
  errorBannerHeight: number;
  stageBannerHeight: number;
}>`
  ${({ theme, fixedMasthead, errorBannerHeight, stageBannerHeight }) =>
    fixedMasthead &&
    css`
      padding-top: ${`${
        Number(theme.proposals.masthead.height.replace('rem', '')) +
        errorBannerHeight +
        stageBannerHeight
      }rem`};
    `}
  ${({ theme, backgroundImage, overlayImage }) => {
    return !!backgroundImage && !!backgroundImage?.src
      ? getBackgroundImageStyles(theme, backgroundImage, overlayImage)
      : getNonBackgroundStyles(theme);
  }}
`;

export const HeroContainer = styled.div<{
  hasSideImage: boolean;
  layout: ReadableHeroLayout;
}>`
  ${({ hasSideImage, layout, theme }) => css`
    position: relative;
    padding: 3rem 0;
    padding-left: 7rem;
    ${hasSideImage && getBackground(theme, layout.transparentBackground)}
    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        padding: 0 1.5rem;
      `
    )}
  `}
  ${({ theme, layout }) => {
    switch (layout.type) {
      case ReadableHeroTypes.IMAGE_AND_SQUARE_BACKGROUND:
      case ReadableHeroTypes.IMAGE_AND_ROUNDED_BACKGROUND:
        return css`
          ${editModeMobile(
            theme.breakpoints.down('sm'),
            css`
              padding: 1.5rem 0.5rem;
            `
          )}
        `;

      case ReadableHeroTypes.SQUARE_IMAGE_AND_SOLID_COLOUR:
        return css`
          width: 60%;
          padding: 3rem 7rem 3rem 7rem;
          ${editModeMobile(
            theme.breakpoints.down('sm'),
            css`
              padding: 0;
              width: 100%;
            `
          )}
        `;
      case ReadableHeroTypes.SHAPED_IMAGE:
        return css`
          width: 60%;
          &:before {
            content: '';
            position: absolute;
            top: calc(-15% / 2);
            right: -20%;
            width: 65%;
            height: 115%;
            border-radius: 0 50% 50% 0;
            ${getBackground(theme, false)}
          }
          ${editModeMobile(
            theme.breakpoints.down('sm'),
            css`
              padding: 0;
              width: 100%;
              &:before {
                width: 0;
                height: 0;
              }
            `
          )}
        `;
    }
  }}
`;

export const Content = styled.div<{
  isEditMode?: boolean;
  layout: ReadableHeroLayout;
}>`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 1rem;
    position: relative;
    z-index: 10;

    .hub-hero-title {
      width: fit-content;
    }
    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        width: 100%;
        margin: 0 0.75rem;
        max-width: 95%;
      `
    )}
  `}
  ${({ layout, theme }) => {
    switch (layout.type) {
      case ReadableHeroTypes.DEFAULT:
        return ``;
      case ReadableHeroTypes.IMAGE_AND_HIGHLIGHTED_TEXT:
        return `
        .hub-hero-description {
          p {
            padding-left: 0.125rem;
          }
        }
        .hub-hero-title,
        .email-gdpr-info-text{
          padding: 0rem 0.3125rem;
          box-decoration-break: clone;
          ${getBackground(theme, layout.transparentBackground)}
          }
        `;
      case ReadableHeroTypes.IMAGE_AND_SQUARE_BACKGROUND:
        return `
          ${getBackground(theme, layout.transparentBackground)}

        `;
      case ReadableHeroTypes.IMAGE_AND_ROUNDED_BACKGROUND:
        return `
          border-radius: 0.75rem;
          ${getBackground(theme, layout.transparentBackground)}
        `;
    }
  }}
`;

export const Title = styled(MuiTypography)`
  max-width: 32.8125rem;
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0 0 0.625rem 0;

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        max-width: 75%;
      `
    )}

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        max-width: 100%;
        font-size: 2rem;
        line-height: 1.2;
      `
    )}
`;

export const Description = styled(HtmlDescription)`
  font-size: ${({ theme }) => theme.fontSizes.m};
  max-width: 32.8125rem;
  margin: 0.625rem 0;

  a {
    text-decoration: underline;
    color: white;
  }

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        max-width: 75%;
      `
    )}

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        max-width: 100%;
      `
    )}
`;

export const Overlay = styled.div<{ backgroundImage: HeroCustomBackground }>`
  ${({ theme, backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${getCachedCloudinaryUrl(
        backgroundImage.src,
        IMAGE_CLOUDINARY_TYPES.HERO
      )});
      ${backgroundImage.customCSS};
      ${editModeMobile(
        theme.breakpoints.down('xl'),
        css`
          ${backgroundImage.customCSS.xl};
        `
      )};
      ${editModeMobile(
        theme.breakpoints.down('lg'),
        css`
          ${backgroundImage.customCSS.lg};
        `
      )};
      ${editModeMobile(
        theme.breakpoints.down('md'),
        css`
          ${backgroundImage.customCSS.md};
        `
      )};
      ${editModeMobile(
        theme.breakpoints.down('sm'),
        css`
          ${backgroundImage.customCSS.sm};
        `
      )};
      ${editModeMobile(
        theme.breakpoints.down('xs'),
        css`
          ${backgroundImage.customCSS.xs};
        `
      )};
    `}
`;
