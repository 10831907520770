import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ArrowIcon2, ChevronLeftIcon } from 'Atoms/Icons';
import { RingLoader } from 'Atoms';
import { BackButton, NextButton, Wrapper } from './index.styles';

interface NavigationButtonsProps {
  handleNextClick: () => void;
  handleBackClick: () => void;
  disableButtons: {
    next: boolean;
    back: boolean;
  };
  hideButtons: {
    next: boolean;
    back: boolean;
  };
  loading: boolean;
}
export const NavigationButtons = ({
  handleNextClick,
  handleBackClick,
  disableButtons,
  hideButtons,
  loading,
}: NavigationButtonsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Wrapper>
      <BackButton
        data-testid="cf-back-button"
        onClick={handleBackClick}
        disabled={disableButtons.back}
        hidden={hideButtons.back}
      >
        <ChevronLeftIcon color={theme['colors'].green['A700']} height={12} />
        {t('Back')}
      </BackButton>

      <NextButton
        data-testid="cf-next-button"
        onClick={handleNextClick}
        disabled={disableButtons.next}
        hidden={hideButtons.next}
      >
        {t('Next')}
        {loading ? (
          <RingLoader
            data-testid="loading-icon"
            color={'white'}
            loaderSize={15}
            width={'unset'}
            height={'22px'}
            marginLeft={'0px'}
          />
        ) : (
          <ArrowIcon2 height={22} width={20} />
        )}
      </NextButton>
    </Wrapper>
  );
};
